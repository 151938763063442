import * as React from "react";
import PropTypes from "prop-types";
import { IMaskMixin } from "react-imask";
import MDInput from "../MDInput";

const MaskedTextField = IMaskMixin(({ inputRef, ...otherProps }) => (
  <MDInput {...otherProps} inputRef={inputRef} />
));

MaskedTextField.propTypes = {
  mask: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MaskedTextField;
