import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/CTFramework/MDBox";
import DataTable from "components/DataTable";

function List({ rows }) {
  const columns = [
    { Header: "Nome", accessor: "name", width: "200px", align: "left" },
    { Header: "E-mail", accessor: "email", width: "200px", align: "left" },
    { Header: "CPF", accessor: "document", align: "left" },
    { Header: "Telefone", accessor: "phone", align: "left" },
    { Header: "Data Nascimento", accessor: "birthDate", align: "left" },
    { Header: "", accessor: "action", align: "right", width: "5px" },
  ];

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} pb={2} px={2}>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <DataTable
            table={{ columns, rows }}
            noEndBorder
            entriesPerPage={false}
            isSorted={false}
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

List.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default List;
