import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CircularProgress, Modal, TextField } from "@mui/material";
import { toast } from "react-toastify";

import MDBox from "components/CTFramework/MDBox";
import MDInput from "components/CTFramework/MDInput";
import MDTypography from "components/CTFramework/MDTypography";
import MDButton from "components/CTFramework/MDButton";
import { AxiosContext } from "context";
import MaskedTextField from "components/CTFramework/MaskedTextField";
import { DatePicker } from "@mui/x-date-pickers";

function NewClient({ isOpen, onClose }) {
  const { authAxios } = useContext(AxiosContext);

  const [birthDate, setBirthDate] = useState();
  const [document, setDocument] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [saving, setSaving] = useState(false);

  const save = async () => {
    if (name !== "") {
      try {
        setSaving(true);

        const response = await authAxios.post("/crm/v1/clients", {
          birthDate,
          document,
          email,
          name,
          phone,
        });

        const { id } = response.data;

        onClose(true, id);
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setSaving(false);
      }
    } else {
      toast.error("Preencha os campos obrigatórios!");
    }
  };

  useEffect(() => {
    if (isOpen) {
      setBirthDate();
      setDocument("");
      setEmail("");
      setName("");
      setPhone("");
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={() => onClose(false)}>
      <MDBox
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
        }}
      >
        <Card color="primary">
          <MDBox
            variant="contained"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Novo Cliente
            </MDTypography>
          </MDBox>
          <MDBox display="flex" flexDirection="column">
            <MDBox display="flex" flexDirection="row" mt={2}>
              <MDBox mb={2} mx={2}>
                <MDInput
                  type="text"
                  label="Nome"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </MDBox>

              <MDBox mb={2} mx={2}>
                <MDInput
                  name="email"
                  type="text"
                  label="E-mail"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </MDBox>
            </MDBox>

            <MDBox display="flex" flexDirection="row" mt={1}>
              <MDBox mb={2} mx={2} mt={1}>
                <MaskedTextField
                  name="phone"
                  label="Telefone"
                  placeholder="(00) 00000-0000"
                  fullWidth
                  value={phone}
                  onAccept={(value) => {
                    setPhone(value);
                  }}
                  onChange={(e) => setPhone(e.target.value)}
                  mask="(00) 00000-0000"
                />
              </MDBox>

              <MDBox mb={2} mx={2} mt={1}>
                <MaskedTextField
                  name="document"
                  label="CPF"
                  placeholder="000.000.000-00"
                  fullWidth
                  value={document}
                  onAccept={(value) => {
                    setDocument(value);
                  }}
                  onChange={(e) => {
                    setDocument(e.target.value);
                  }}
                  mask="000.000.000-00"
                />
              </MDBox>

              <MDBox mb={2} mx={2} mt={1}>
                <DatePicker
                  label="Data Nascimento"
                  value={birthDate}
                  onChange={setBirthDate}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} />}
                />
              </MDBox>
            </MDBox>
          </MDBox>

          <MDBox mt={4} mb={1} mr={1} ml={1} display="flex" justifyContent="space-between">
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => {
                onClose(false);
              }}
            >
              Voltar
            </MDButton>
            <MDButton variant="gradient" color="primary" onClick={save}>
              {saving ? (
                <MDBox display="flex" justifyContent="space-between" color="text">
                  <CircularProgress color="text" size={15} />
                  &nbsp;Salvando
                </MDBox>
              ) : (
                "Salvar"
              )}
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
  );
}

NewClient.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewClient;
