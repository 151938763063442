import { useContext, useEffect, useState } from "react";
import { ArrowBackRounded } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers";
import { AxiosContext } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MDBox from "components/CTFramework/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MaskedTextField from "components/CTFramework/MaskedTextField";
import MDInput from "components/CTFramework/MDInput";
import { Backdrop, Card, CircularProgress, Tab, Tabs, TextField } from "@mui/material";
import MDButton from "components/CTFramework/MDButton";
import MDTypography from "components/CTFramework/MDTypography";
import Formatters from "helpers/formatters";
import { parseISO } from "date-fns";
import ListTickets from "./components/ListTickets";
import ListTransactions from "./components/ListTransactions";

function Client() {
  const { authAxios } = useContext(AxiosContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [client, setClient] = useState({});
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [valueBuy, setValueBuy] = useState(0);

  const parseTickets = (items) => {
    setTickets(
      items.map((item) => ({
        program: (
          <MDTypography variant="button" fontWeight="regular" sx={{ width: 150, display: "flex" }}>
            {item.program?.config.name} - {item.program?.description}
          </MDTypography>
        ),
        passengers: item.passengers.map((passenger) => (
          <MDTypography
            key={passenger.id}
            variant="button"
            fontWeight="regular"
            sx={{ width: 200, display: "flex" }}
          >
            {passenger.document} - {passenger.name}
          </MDTypography>
        )),
        date: (
          <MDTypography variant="caption" fontWeight="regular">
            {Formatters.dateShort(parseISO(item.dateIssued))}
          </MDTypography>
        ),
        locator: (
          <MDTypography variant="caption" fontWeight="regular">
            {item.locator}
          </MDTypography>
        ),
      }))
    );
  };

  const parseTransactions = (items) => {
    setTransactions(
      items.map((item) => ({
        program: (
          <MDTypography variant="button" fontWeight="regular" sx={{ width: 200, display: "flex" }}>
            {item.program?.config.name} - {item.program?.description}
          </MDTypography>
        ),
        description: (
          <MDTypography variant="button" fontWeight="regular" sx={{ width: 200, display: "flex" }}>
            {item.description}
          </MDTypography>
        ),
        date: (
          <MDTypography variant="caption" fontWeight="regular">
            {Formatters.dateShort(parseISO(item.date))}
          </MDTypography>
        ),
        value: (
          <MDTypography variant="caption" fontWeight="regular">
            {Formatters.currency(item.amount * item.costPerUnit)}
          </MDTypography>
        ),
      }))
    );
  };

  const fetchClient = () => {
    setLoading(true);
    authAxios
      .get(`/crm/v1/clients/${location.state.id}`)
      .then((response) => {
        setClient(response.data);
        setValueBuy(response.data.transactions.reduce((a, c) => a + c.amount * c.costPerUnit, 0));
        parseTickets(
          response.data.tickets.sort((a, b) => new Date(b.dateIssued) - new Date(a.dateIssued))
        );
        parseTransactions(
          response.data.transactions.sort((a, b) => new Date(b.date) - new Date(a.date))
        );
      })
      .finally(() => setLoading(false));
  };

  const save = async () => {
    if (client.name !== "") {
      try {
        setSaving(true);

        await authAxios.put("/crm/v1/clients", {
          id: client.id,
          birthDate: client.birthDate,
          document: client.document,
          email: client.email,
          name: client.name,
          phone: client.phone,
        });
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setSaving(false);
        setEditing(false);
      }
    } else {
      toast.error("Preencha os campos obrigatórios!");
    }
  };

  useEffect(() => {
    fetchClient();
  }, [location]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {!loading && (
        <MDBox>
          <Card>
            {editing && (
              <MDBox>
                <MDBox mb={2} mx={2} mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDInput
                        type="text"
                        label="Nome"
                        fullWidth
                        value={client.name}
                        onChange={(e) => setClient({ ...client, name: e.target.value })}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDInput
                        name="email"
                        type="text"
                        label="E-mail"
                        fullWidth
                        value={client.email}
                        onChange={(e) => setClient({ ...client, email: e.target.value })}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mb={2} mx={2} mt={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4} xl={4}>
                      <MaskedTextField
                        name="phone"
                        label="Telefone"
                        placeholder="(00) 00000-0000"
                        fullWidth
                        value={client.phone}
                        onAccept={(value) => {
                          setClient({ ...client, phone: value });
                        }}
                        onChange={(e) => setClient({ ...client, phone: e.target.value })}
                        mask="(00) 00000-0000"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                      <MaskedTextField
                        name="document"
                        label="CPF"
                        placeholder="000.000.000-00"
                        fullWidth
                        value={client.document}
                        onAccept={(value) => {
                          setClient({ ...client, document: value });
                        }}
                        onChange={(e) => {
                          setClient({ ...client, document: e.target.value });
                        }}
                        mask="000.000.000-00"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                      <DatePicker
                        label="Data Nascimento"
                        value={client.birthDate}
                        onChange={(value) => setClient({ ...client, birthDate: value })}
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} />}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            )}
            {!editing && (
              <MDBox>
                <MDBox mb={2} mx={2} mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDTypography variant="subtitle1" fontWeight="regular">
                        Nome:
                      </MDTypography>
                      <MDTypography variant="caption" fontWeight="regular">
                        {client.name}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDTypography variant="subtitle1" fontWeight="regular">
                        E-mail:
                      </MDTypography>
                      <MDTypography variant="caption" fontWeight="regular">
                        {client.email}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mb={2} mx={2} mt={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4} xl={4}>
                      <MDTypography variant="subtitle1" fontWeight="regular">
                        Telefone:
                      </MDTypography>
                      <MDTypography variant="caption" fontWeight="regular">
                        {client.phone}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                      <MDTypography variant="subtitle1" fontWeight="regular">
                        CPF:
                      </MDTypography>
                      <MDTypography variant="caption" fontWeight="regular">
                        {client.document}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                      <MDTypography variant="subtitle1" fontWeight="regular">
                        Data Nascimento:
                      </MDTypography>
                      <MDTypography variant="caption" fontWeight="regular">
                        {client.birthDate ? Formatters.dateShort(parseISO(client.birthDate)) : ""}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mb={2} mx={2} mt={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDTypography variant="subtitle1" fontWeight="regular">
                        Total comprado:
                      </MDTypography>
                      <MDTypography variant="h5" fontWeight="regular" color="success">
                        {Formatters.currency(valueBuy)}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            )}
            <Grid item xs={12} md={6} xl={12}>
              <MDBox mt={4} mb={1} mr={1} ml={1} display="flex" justifyContent="space-between">
                <MDButton
                  variant="gradient"
                  color="secondary"
                  onClick={() => {
                    navigate(-1);
                  }}
                  startIcon={<ArrowBackRounded />}
                >
                  Voltar
                </MDButton>
                <MDBox display="flex" justifyContent="flex-end">
                  {!editing && (
                    <MDButton
                      variant="gradient"
                      color="primary"
                      onClick={() => setEditing(true)}
                      sx={{ marginLeft: 10 }}
                    >
                      Editar
                    </MDButton>
                  )}
                  {editing && (
                    <MDBox>
                      <MDButton
                        variant="gradient"
                        color="secondary"
                        onClick={() => {
                          setEditing(false);
                          fetchClient();
                        }}
                        sx={{ marginLeft: 10 }}
                      >
                        Cancelar
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="primary"
                        onClick={save}
                        sx={{ marginLeft: 10 }}
                      >
                        {saving ? (
                          <MDBox display="flex" justifyContent="space-between" color="text">
                            <CircularProgress color="text" size={15} />
                            &nbsp;Salvando
                          </MDBox>
                        ) : (
                          "Salvar"
                        )}
                      </MDButton>
                    </MDBox>
                  )}
                </MDBox>
              </MDBox>
            </Grid>
          </Card>
          <MDBox mt={5} mb={3}>
            <Card>
              <MDBox sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={(_e, value) => setTabValue(value)}>
                  <Tab label="Compras Efetuadas" />
                  <Tab label="Passagem Emitidas" />
                </Tabs>
              </MDBox>
              {tabValue === 0 && (
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <ListTransactions rows={transactions} />
                  </Grid>
                </Grid>
              )}

              {tabValue === 1 && (
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <ListTickets rows={tickets} />
                  </Grid>
                </Grid>
              )}
            </Card>
          </MDBox>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Client;
