import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/CTFramework/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

import { Autocomplete, Card, Icon, IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import MDButton from "components/CTFramework/MDButton";
import MDTypography from "components/CTFramework/MDTypography";
import NewTransaction from "components/NewTransaction";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";
import { AxiosContext } from "context";
import Formatters from "helpers/formatters";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfMonth, format, parseISO, startOfMonth } from "date-fns";
import ProgramCard from "components/Cards/ProgramCard";
import MDInput from "components/CTFramework/MDInput";
import { useIntl } from "react-intl";
import TransactionTypeEnum from "enuns/transactionType";
import Utils from "helpers/utils";
import MDSelect from "components/CTFramework/MDSelect";

import List from "./components/List";

function Transactions() {
  const [isOpenNewTransaction, setIsOpenNewTransaction] = useState(false);

  const { authAxios } = useContext(AxiosContext);
  const confirm = useConfirm();
  const { formatMessage } = useIntl();

  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState({ id: "" });
  const [transaction, setTransaction] = useState({});
  const [typeFilter, setTypeFilter] = useState("all");
  const [rows, setRows] = useState([]);
  const [dateStart, setDateStart] = useState(startOfMonth(new Date()));
  const [dateEnd, setDateEnd] = useState(endOfMonth(new Date()));

  async function fetchTransactions() {
    const response = await authAxios.get(
      `/rewards/v1/transactions?programId=${selectedProgram.id}&dateStart=${format(
        dateStart,
        "yyyy-MM-dd"
      )}&dateEnd=${format(dateEnd, "yyyy-MM-dd")}&type=${typeFilter.replace("all", "")}`
    );

    parseRows(response.data);
  }

  const remove = async (id) => {
    try {
      confirm({
        description: "Deseja realmente apagar essa transação?",
      }).then(async () => {
        await authAxios.delete(`/rewards/v1/transactions/${id}`);
        fetchProgram();
        fetchTransactions();
      });
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const clone = (item) => {
    setIsOpenNewTransaction(true);
    setTransaction(item);
  };

  const parseRows = (items) => {
    setRows(
      items.map((item) => ({
        type: (
          <MDTypography
            variant="button"
            fontWeight="medium"
            sx={{ width: 100, display: "flex" }}
            color={
              [TransactionTypeEnum.Incoming, TransactionTypeEnum.Rewards].includes(item.type)
                ? "success"
                : "info"
            }
          >
            {formatMessage({ id: item.type && !item.transfer ? item.type : "transfer" })}
          </MDTypography>
        ),
        description: (
          <MDTypography variant="button" fontWeight="regular" sx={{ width: 400, display: "flex" }}>
            {item.description}
          </MDTypography>
        ),
        date: (
          <MDTypography variant="caption" fontWeight="regular">
            {Formatters.dateShort(parseISO(item.date))}
          </MDTypography>
        ),
        points: (
          <MDTypography variant="button" fontWeight="medium">
            {item.amount}
          </MDTypography>
        ),
        value: (
          <MDTypography variant="button" fontWeight="medium">
            {Formatters.currency(
              item.amount *
                (item.type === TransactionTypeEnum.Outcoming ? item.sellPerUnit : item.costPerUnit)
            )}
          </MDTypography>
        ),
        avaragePrice: (
          <MDTypography variant="button" fontWeight="medium">
            {Formatters.currency(
              Utils.getCostInUnit(
                item.type === TransactionTypeEnum.Outcoming ? item.sellPerUnit : item.costPerUnit,
                item.program.config.unitMeasure
              )
            )}
          </MDTypography>
        ),
        profit: (
          <MDTypography
            variant="button"
            fontWeight="medium"
            color={
              item.amount * item.sellPerUnit - item.amount * item.costPerUnit > 0
                ? "success"
                : "error"
            }
          >
            {item.type === TransactionTypeEnum.Outcoming &&
              Formatters.currency(item.amount * item.sellPerUnit - item.amount * item.costPerUnit)}
          </MDTypography>
        ),
        action: (
          <MDBox display="flex" flexDirection="row">
            <Tooltip title="Copiar dados da transação">
              <IconButton onClick={() => clone(item)}>
                <Icon>content_copy</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Remover transação">
              <IconButton onClick={() => remove(item.id)}>
                <Icon color="error">delete</Icon>
              </IconButton>
            </Tooltip>
          </MDBox>
        ),
      }))
    );
  };

  useEffect(() => {
    if (selectedProgram?.id) {
      fetchTransactions();
      setTransaction({});
    } else if (!selectedProgram?.id) {
      setRows([]);
      setTransaction({});
    }
  }, [dateEnd, dateStart, selectedProgram]);

  const fetchData = async () => {
    authAxios.get("/rewards/v1/programs").then((response) => {
      setPrograms(response.data);
    });
  };

  const fetchProgram = async () => {
    authAxios.get("/rewards/v1/programs").then((response) => {
      setPrograms(response.data);
      setSelectedProgram(response.data.find((p) => p.id === selectedProgram.id));
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3} display="flex">
          {selectedProgram?.id && (
            <MDBox sx={{ width: 300, marginRight: 3 }}>
              <ProgramCard
                color="light"
                program={selectedProgram}
                points={{
                  color: "success",
                }}
              />
            </MDBox>
          )}
          <Card sx={{ height: 252, width: "100%" }}>
            <MDBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="5rem"
              height="3rem"
              mt={-3}
            >
              <MDTypography variant="caption" fontWeight="regular">
                Filtros
              </MDTypography>
            </MDBox>
            <MDBox py={3} px={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <Autocomplete
                    disableClearable
                    value={selectedProgram}
                    options={programs}
                    getOptionLabel={(option) =>
                      option?.config?.name ? `${option.config.name} - ${option.description}` : ""
                    }
                    onChange={(event, newValue) => {
                      setSelectedProgram(newValue);
                    }}
                    fullWidth
                    renderInput={(params) => <MDInput {...params} label="Programa" />}
                    noOptionsText="Programa não encontrado"
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <MDBox display="flex" justifyContent="flex-end">
                    <DatePicker
                      label=""
                      value={dateStart}
                      onChange={setDateStart}
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} />}
                      maxDate={dateEnd}
                    />
                    <MDBox color="text" mx={1} mt={1.5} lineHeight={0}>
                      <MDTypography variant="button" fontWeight="medium">
                        até
                      </MDTypography>
                    </MDBox>
                    <DatePicker
                      label=""
                      value={dateEnd}
                      onChange={setDateEnd}
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} />}
                      minDate={dateStart}
                    />
                  </MDBox>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6} mt={2}>
                  <MDSelect
                    value={typeFilter}
                    onChange={(e) => setTypeFilter(e.target.value)}
                    label="Tipo"
                    style={{ width: 150 }}
                  >
                    <MenuItem value="all">Todas</MenuItem>
                    {Object.keys(TransactionTypeEnum).map((key) => (
                      <MenuItem value={TransactionTypeEnum[key]} key={key}>
                        {formatMessage({ id: TransactionTypeEnum[key] })}
                      </MenuItem>
                    ))}
                  </MDSelect>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox py={3} px={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={12}>
                  <MDBox display="flex" justifyContent="flex-end">
                    <MDButton
                      color="error"
                      onClick={() => {
                        setDateEnd(endOfMonth(new Date()));
                        setDateStart(startOfMonth(new Date()));
                        setSelectedProgram({ id: "" });
                        setRows([]);
                      }}
                    >
                      <Icon>clear</Icon>&nbsp;Limpar
                    </MDButton>
                    <MDButton
                      color="info"
                      onClick={() => {
                        fetchTransactions();
                      }}
                      style={{ marginLeft: "1em" }}
                      disabled={selectedProgram.id === ""}
                    >
                      <Icon>search</Icon>&nbsp;Pesquisar
                    </MDButton>

                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={() => {
                        setTransaction(null);
                        setIsOpenNewTransaction(true);
                      }}
                      style={{ marginLeft: "1em" }}
                    >
                      &nbsp;Nova Transação
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <List rows={rows} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>

      <NewTransaction
        isOpen={isOpenNewTransaction}
        onClose={(reload) => {
          if (reload) {
            fetchTransactions();
            fetchProgram();
          }
          setIsOpenNewTransaction(false);
        }}
        transaction={transaction}
      />
    </DashboardLayout>
  );
}

export default Transactions;
