import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBRLocale from "date-fns/locale/pt-BR";
import { ConfirmProvider } from "material-ui-confirm";

import App from "App";

import { AuthProvider, AxiosProvider, ThemeProvider } from "context";
import reportWebVitals from "reportWebVitals";

import ptBR from "i18n/pt-BR.json";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <AuthProvider>
      <AxiosProvider>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
            <IntlProvider messages={ptBR} locale="pt-BR" defaultLocale="pt-BR">
              <ConfirmProvider
                defaultOptions={{
                  title: "Atenção",
                  cancellationText: "Cancelar",
                  confirmationText: "Sim",
                  confirmationButtonProps: { autoFocus: true },
                }}
              >
                <App />
              </ConfirmProvider>
            </IntlProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </AxiosProvider>
    </AuthProvider>
  </BrowserRouter>
);

reportWebVitals();
