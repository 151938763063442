import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Avatar,
  Card,
  CircularProgress,
  InputAdornment,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Autocomplete,
} from "@mui/material";
import { toast } from "react-toastify";

import MDBox from "components/CTFramework/MDBox";
import MDInput from "components/CTFramework/MDInput";
import MDTypography from "components/CTFramework/MDTypography";
import MDButton from "components/CTFramework/MDButton";
import { AxiosContext } from "context";
import MDSelect from "components/CTFramework/MDSelect";
import Utils from "helpers/utils";
import PaymentMethod from "components/PaymentMethod";
import Formatters from "helpers/formatters";

function NewTransaction({ isOpen, onClose, transaction }) {
  const { authAxios } = useContext(AxiosContext);

  const [amount, setAmount] = useState(0);
  const [cost, setCost] = useState(0);
  const [date, setDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [installments, setInstallments] = useState(1);
  const [loading, setLoading] = useState(false);
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [paymentMethod, setPaymentMethod] = useState("");
  const [programs, setPrograms] = useState([]);
  const [saving, setSaving] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState({ id: "" });
  const [type, setType] = useState("");

  const save = async () => {
    if (
      (selectedProgram.id !== "" && amount >= 0 && cost >= 0 && type !== "") ||
      (type !== "rewards" && paymentMethod !== "" && installments > 0)
    ) {
      try {
        setSaving(true);
        await authAxios.post("/rewards/v1/transactions", {
          amount,
          costPerUnit: cost / amount,
          date,
          description,
          type,
          payment: {
            dateStart: paymentDate,
            installments: installments || 1,
            method: paymentMethod || "",
            value: cost || 0,
          },
          program: selectedProgram,
        });

        onClose(true);
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setSaving(false);
      }
    } else {
      toast.error("Preencha os campos obrigatórios");
    }
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      authAxios.get(`/rewards/v1/programs`).then((response) => {
        setPrograms(response.data);

        setAmount(0);
        setCost(0);
        setDate(new Date());
        setDescription("");
        setInstallments(1);
        setPaymentDate(new Date());
        setPaymentMethod("");
        setSelectedProgram({ id: "" });
        setType("");

        if (transaction?.id) {
          setAmount(transaction.amount);
          setCost(Number.parseFloat(transaction.amount * transaction.costPerUnit).toFixed(2));
          setDescription(transaction.description);
          setInstallments(transaction.payment?.installments);
          setPaymentDate(transaction.payment?.dateStart);
          setPaymentMethod(transaction.payment?.method);
          setSelectedProgram(response.data.find((p) => p.id === transaction.program.id));
          setType(transaction.type);
        }

        setLoading(false);
      });
    }
  }, [isOpen, transaction]);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setType("");
        onClose(false);
      }}
    >
      <MDBox
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: type !== "rewards" ? 670 : 300,
        }}
      >
        <Card color="primary" sx={{ minHeight: 525 }}>
          <MDBox
            variant={selectedProgram.id !== "" ? "contained" : "gradient"}
            bgColor={selectedProgram.id !== "" ? selectedProgram.config.color : "info"}
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Nova transação
            </MDTypography>
          </MDBox>

          {loading && (
            <MDBox
              style={{
                marginLeft: "48%",
                marginTop: "28%",
              }}
            >
              <CircularProgress color="inherit" />
            </MDBox>
          )}
          {!loading && (
            <>
              <MDBox display="flex" flexDirection="row">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={type !== "rewards" ? 6 : 12}>
                    <MDBox display="flex" flexDirection="column">
                      <MDBox mb={2} mx={2} mt={1} display="flex" flexDirection="row">
                        <Autocomplete
                          disableClearable
                          value={selectedProgram}
                          options={programs}
                          getOptionLabel={(option) =>
                            option?.config?.name
                              ? `${option.config.name} - ${option.description}`
                              : ""
                          }
                          onChange={(event, newValue) => {
                            setSelectedProgram(newValue);
                          }}
                          fullWidth
                          renderInput={(params) => <MDInput {...params} label="Programa" />}
                          noOptionsText="Programa não encontrado"
                        />

                        {selectedProgram?.config?.icon && (
                          <MDBox ml={1}>
                            <Avatar
                              variant="rounded"
                              sizes="small"
                              src={`img/icons/programs/${selectedProgram.config.icon}`}
                            />
                          </MDBox>
                        )}
                      </MDBox>

                      <MDBox mb={2} mx={2} mt={1}>
                        <MDSelect
                          value={type}
                          error={!type}
                          onChange={(e) => setType(e.target.value)}
                          label="Tipo"
                          fullWidth
                        >
                          <MenuItem value="">Selecione</MenuItem>
                          <MenuItem value="rewards">Bônus</MenuItem>
                          <MenuItem value="incoming">Compra</MenuItem>
                          <MenuItem value="outcoming">Venda</MenuItem>
                        </MDSelect>
                      </MDBox>

                      <MDBox mb={2} mx={2}>
                        <DatePicker
                          label="Data da transação"
                          inputFormat="dd/MM/yyyy"
                          value={date}
                          onChange={(newDate) => {
                            setDate(newDate);
                            setPaymentDate(newDate);
                          }}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      </MDBox>

                      <MDBox mb={2} mx={2}>
                        <MDInput
                          type="text"
                          label="Descrição"
                          fullWidth
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </MDBox>

                      <MDBox mb={2} mx={2}>
                        <MDInput
                          type="number"
                          label="Quantidade"
                          fullWidth
                          value={amount}
                          error={amount < 0}
                          onChange={(e) => setAmount(Number(e.target.value))}
                          InputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                        />
                      </MDBox>

                      <MDBox mb={2} mx={2}>
                        <MDInput
                          type="number"
                          label="Valor total"
                          fullWidth
                          value={cost}
                          error={cost < 0}
                          onChange={(e) => setCost(Number(e.target.value))}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                        />
                      </MDBox>
                      {cost >= 0 && amount >= 0 && selectedProgram.config && (
                        <MDBox
                          p={2}
                          mx={2}
                          display="flex"
                          flexDirection="column"
                          justifyContent="right"
                          shadow="md"
                          coloredShadow="light"
                          borderRadius="lg"
                        >
                          <MDTypography component="p" variant="button" color="text" display="flex">
                            Preço medio&nbsp;
                          </MDTypography>
                          <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="bold"
                            color="success"
                          >
                            {Formatters.currency(
                              Utils.getCostInUnit(cost / amount, selectedProgram.config.unitMeasure)
                            )}
                          </MDTypography>
                        </MDBox>
                      )}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={type !== "rewards" ? 6 : 0}>
                    {type !== "rewards" && (
                      <MDBox display="flex" flexDirection="column" mt={1}>
                        <PaymentMethod
                          cost={cost || 0}
                          installments={installments}
                          setInstallments={setInstallments}
                          paymentDate={paymentDate}
                          setPaymentDate={setPaymentDate}
                          paymentMethod={paymentMethod}
                          setPaymentMethod={setPaymentMethod}
                        />
                      </MDBox>
                    )}
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox mt={4} mb={1} mr={1} ml={1} display="flex" justifyContent="space-between">
                <MDButton
                  variant="gradient"
                  color="secondary"
                  onClick={() => {
                    setType("");
                    onClose(false);
                  }}
                >
                  Voltar
                </MDButton>
                <MDButton variant="gradient" color="primary" onClick={save}>
                  {saving ? (
                    <MDBox display="flex" justifyContent="space-between" color="text">
                      <CircularProgress color="text" size={15} />
                      &nbsp;Salvando
                    </MDBox>
                  ) : (
                    "Salvar"
                  )}
                </MDButton>
              </MDBox>
            </>
          )}
        </Card>
      </MDBox>
    </Modal>
  );
}

NewTransaction.defaultProps = {
  transaction: {},
};

NewTransaction.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  transaction: PropTypes.object,
};

export default NewTransaction;
