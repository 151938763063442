/* eslint-disable react/style-prop-object */
import { FormattedNumber, FormattedDate } from "react-intl";

export default class Formatters {
  static currency(value) {
    return <FormattedNumber value={value || 0} style="currency" currency="BRL" />;
  }

  static number(value, digits = 0) {
    return <FormattedNumber value={value || 0} style="decimal" maximumFractionDigits={digits} />;
  }

  static dateLong(value) {
    return <FormattedDate value={value} year="numeric" month="long" day="numeric" weekday="long" />;
  }

  static dateTime(value) {
    return <FormattedDate value={value} dateStyle="short" timeStyle="short" />;
  }

  static dateShort(value) {
    return <FormattedDate value={value} />;
  }
}
