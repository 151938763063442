import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Avatar } from "@mui/material";

import MDBox from "components/CTFramework/MDBox";
import MDTypography from "components/CTFramework/MDTypography";

// Images
import pattern from "assets/images/illustrations/pattern-tree.svg";

function CreditCard({ brand, color, number, holder, expires, payDay, closeDay }) {
  return (
    <Card
      sx={({ palette: { gradients }, functions: { linearGradient }, boxShadows: { xl } }) => ({
        background: gradients[color]
          ? linearGradient(gradients[color].main, gradients[color].state)
          : linearGradient(gradients.dark.main, gradients.dark.state),
        boxShadow: xl,
        position: "relative",
      })}
    >
      <MDBox
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        opacity={0.2}
        sx={{
          backgroundImage: `url(${pattern})`,
          backgroundSize: "cover",
        }}
      />
      <MDBox position="relative" zIndex={2} px={2}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox color="white" mt={-5} lineHeight={0} display="inline-block">
            <Icon fontSize="default">wifi</Icon>
          </MDBox>
          <MDTypography variant="h5" color="white" fontWeight="medium" sx={{ mt: 3, mb: 5, pb: 1 }}>
            {number}
          </MDTypography>
        </MDBox>

        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" alignItems="center">
            <MDBox mr={3} lineHeight={1} sx={{ width: "220px" }}>
              <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Nome
              </MDTypography>
              <MDTypography
                variant="h6"
                color="white"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {holder}
              </MDTypography>
            </MDBox>
            <MDBox lineHeight={1}>
              <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Válido Até
              </MDTypography>
              <MDTypography variant="h6" color="white" fontWeight="medium">
                {expires}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>

        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1}>
          <MDBox display="flex" alignItems="center">
            <MDBox lineHeight={1} sx={{ width: "100px" }}>
              <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Melhor data Compra
              </MDTypography>
              <MDTypography variant="h6" color="white" fontWeight="medium">
                {closeDay}
              </MDTypography>
            </MDBox>
            <MDBox lineHeight={1} sx={{ width: "100px" }}>
              <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Vencimento Fatura
              </MDTypography>
              <MDTypography variant="h6" color="white" fontWeight="medium">
                {payDay}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" width="20%">
            <MDBox width="60%" mt={1}>
              <Avatar variant="rounded" sizes="small" src={`img/icons/cards/${brand}.png`} />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

CreditCard.defaultProps = {
  color: "dark",
};

CreditCard.propTypes = {
  brand: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  number: PropTypes.string.isRequired,
  holder: PropTypes.string.isRequired,
  expires: PropTypes.string.isRequired,
  closeDay: PropTypes.number.isRequired,
  payDay: PropTypes.number.isRequired,
};

export default CreditCard;
