import PropTypes from "prop-types";

import MDBox from "components/CTFramework/MDBox";
import MDInput from "components/CTFramework/MDInput";
import { Avatar, Card, Icon, MenuItem, Modal, TextField } from "@mui/material";
import MDTypography from "components/CTFramework/MDTypography";
import MDButton from "components/CTFramework/MDButton";
import { useContext } from "react";
import { AxiosContext } from "context";
import MDSelect from "components/CTFramework/MDSelect";
import { DatePicker } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";
import StatusEnum from "enuns/status";

function NewCard({ isOpenModal, setIsOpenModal, card, setCard }) {
  const { authAxios } = useContext(AxiosContext);
  const confirm = useConfirm();

  const changeCard = (event) => {
    if (event.target.value.length >= 15 && event.target.value.length < 20) {
      setCard({ ...card, number: event.target.value });
    }
  };

  const save = async () => {
    if (card.brand !== "" && card.name !== "" && card.number !== "**** **** **** ") {
      try {
        if (card.id) {
          await authAxios.patch(`/user/v1/cards/${card.id}`, card);
        } else {
          await authAxios.post("/user/v1/cards", card);
        }

        setIsOpenModal(false);
      } catch (err) {
        toast.error(err.response.data.message);
      }
    } else {
      toast.error("Preencha os campos obrigatórios");
    }
  };

  const remove = async () => {
    try {
      if (card.id) {
        confirm({
          description: "Deseja realmente apagar esse cartão?",
        }).then(async () => {
          await authAxios.delete(`/user/v1/cards/${card.id}`, card);

          setIsOpenModal(false);
        });
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const cancelar = async () => {
    try {
      if (card.id) {
        confirm({
          description: "Deseja realmente cancelar esse cartão?",
        }).then(async () => {
          await authAxios.patch(`/user/v1/cards/${card.id}`, { status: StatusEnum.Canceled });

          setIsOpenModal(false);
        });
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <Modal open={isOpenModal} onClose={() => setIsOpenModal(true)}>
      <MDBox
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
        }}
      >
        <Card color="primary">
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Cartão {card.name && ` - ${card.name}`}
            </MDTypography>

            {card.id && (
              <MDBox mt={1}>
                <Icon fontSize="default" sx={{ cursor: "pointer" }} onClick={remove}>
                  delete_forever
                </Icon>
              </MDBox>
            )}
          </MDBox>
          <MDBox>
            <MDBox display="flex" flexDirection="column">
              <MDBox mb={2} mx={2} mt={1} display="flex" flexDirection="row">
                <MDSelect
                  value={card.brand}
                  error={card.brand === ""}
                  onChange={(e) => setCard({ ...card, brand: e.target.value })}
                  label="Bandeira"
                  fullWidth
                >
                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem value="mastercard">MasterCard</MenuItem>
                  <MenuItem value="visa">Visa</MenuItem>
                  <MenuItem value="elo">Elo</MenuItem>
                  <MenuItem value="amex">Amex</MenuItem>
                  <MenuItem value="diniers">Diners</MenuItem>
                  <MenuItem value="outhers">Outras</MenuItem>
                </MDSelect>

                {card.brand !== "" && (
                  <MDBox ml={1}>
                    <Avatar
                      variant="rounded"
                      sizes="small"
                      src={`img/icons/cards/${card.brand}.png`}
                    />
                  </MDBox>
                )}
              </MDBox>

              <MDBox mb={2} mx={2}>
                <MDInput
                  type="text"
                  label="Nome"
                  fullWidth
                  value={card.name}
                  error={card.name === ""}
                  onChange={(e) => setCard({ ...card, name: e.target.value })}
                />
              </MDBox>
              <MDBox mb={2} mx={2}>
                <MDInput
                  type="string"
                  label="Numero"
                  fullWidth
                  value={card.number}
                  error={card.number?.length !== 19}
                  onChange={changeCard}
                />
              </MDBox>
              <MDBox mb={2} mx={2}>
                <DatePicker
                  disablePast
                  views={["year", "month"]}
                  label="Válido até"
                  inputFormat="MM/yy"
                  value={card.expires}
                  onChange={(newValue) => setCard({ ...card, expires: newValue })}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </MDBox>

              <MDBox
                mb={2}
                mx={2}
                mt={1}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <MDInput
                  type="number"
                  label="Melhor dia Compra"
                  value={card.closeDay}
                  onChange={(e) => {
                    let value = parseInt(e.target.value, 10);

                    if (value > 31) value = 31;
                    if (value < 1) value = 1;
                    setCard({ ...card, closeDay: value });
                  }}
                  sx={{ width: "45%" }}
                  InputProps={{ inputProps: { min: 0, max: 31 } }}
                />

                <MDInput
                  type="number"
                  label="Pagamento Fatura"
                  value={card.payDay}
                  onChange={(e) => {
                    let value = parseInt(e.target.value, 10);

                    if (value > 31) value = 31;
                    if (value < 1) value = 1;
                    setCard({ ...card, payDay: value });
                  }}
                  sx={{ width: "45%" }}
                  InputProps={{ inputProps: { min: 0, max: 31 } }}
                />
              </MDBox>
            </MDBox>
          </MDBox>

          <MDBox mt={4} mb={1} mr={1} ml={1} display="flex" justifyContent="space-between">
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => {
                setIsOpenModal(false);
              }}
            >
              Voltar
            </MDButton>
            {card.id && (
              <MDButton variant="gradient" color="warning" onClick={cancelar}>
                Cancelar
              </MDButton>
            )}
            <MDButton variant="gradient" color="primary" onClick={save}>
              Salvar
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
  );
}

NewCard.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  setIsOpenModal: PropTypes.func.isRequired,
  card: PropTypes.object.isRequired,
  setCard: PropTypes.func.isRequired,
};

export default NewCard;
