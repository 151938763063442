import PropTypes from "prop-types";

import MDBox from "components/CTFramework/MDBox";
import MDTypography from "components/CTFramework/MDTypography";

function Invoice({ date, id, price, noGutter }) {
  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <MDBox lineHeight={1.125}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {date}
        </MDTypography>
        <MDTypography variant="caption" fontWeight="regular" color="text">
          {id}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="button" fontWeight="regular" color="text">
          {price}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

Invoice.defaultProps = {
  id: "",
  noGutter: false,
};

Invoice.propTypes = {
  date: PropTypes.string.isRequired,
  id: PropTypes.string,
  price: PropTypes.object.isRequired,
  noGutter: PropTypes.bool,
};

export default Invoice;
