import PropTypes from "prop-types";

import MDBox from "components/CTFramework/MDBox";
import MDInput from "components/CTFramework/MDInput";
import { MenuItem, TextField } from "@mui/material";
import MDTypography from "components/CTFramework/MDTypography";
import { useContext, useEffect, useState } from "react";
import { AxiosContext } from "context";
import MDSelect from "components/CTFramework/MDSelect";
import { DatePicker } from "@mui/x-date-pickers";
import Invoice from "components/Invoice";
import { addMonths, format, isValid, parseISO } from "date-fns";
import Formatters from "helpers/formatters";

function PaymentMethod({
  cost,
  disabled,
  installments,
  setInstallments,
  paymentDate,
  setPaymentDate,
  paymentMethod,
  setPaymentMethod,
}) {
  const { authAxios } = useContext(AxiosContext);

  const [cards, setCards] = useState([]);

  const getTimeLine = () => {
    const invoices = [];
    let processDate = paymentDate;
    if (!isValid(paymentDate)) {
      processDate = parseISO(paymentDate);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < installments; i++) {
      if (isValid(processDate)) {
        invoices.push(
          <Invoice
            date={format(addMonths(processDate, i), "dd/MM/yyyy")}
            price={Formatters.currency(cost / installments)}
            noGutter
            key={i}
          />
        );
      }
    }

    return invoices;
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await authAxios.get(`/user/v1/cards?status=active`);
      setCards(response.data);
    };

    fetchData();
  }, []);

  return (
    <MDBox display="flex" flexDirection="column">
      <MDBox mb={2} mx={2}>
        <MDSelect
          value={paymentMethod}
          error={!paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
          label="Pagando com"
          fullWidth
          disabled={disabled}
        >
          <MenuItem value="">Selecione</MenuItem>
          <MenuItem value="money">Dinheiro</MenuItem>
          {cards.map((card) => (
            <MenuItem key={card.id} value={card.id}>
              {card.name} - {card.number}
            </MenuItem>
          ))}
        </MDSelect>
      </MDBox>
      <MDBox display="flex" flexDirection="row">
        <MDBox mb={2} mx={2} mt={1}>
          <MDInput
            type="number"
            label="Parcelas"
            value={installments}
            error={installments === undefined}
            style={{ width: 80 }}
            onChange={(e) => setInstallments(Number(e.target.value))}
            InputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            disabled={disabled}
          />
        </MDBox>
        <MDBox mb={2} mx={2} mt={1}>
          <DatePicker
            label={installments === 1 ? "Data do pagamento" : "Data da primeira parcela"}
            inputFormat="dd/MM/yyyy"
            value={paymentDate}
            onChange={setPaymentDate}
            renderInput={(params) => <TextField {...params} fullWidth />}
            disabled={disabled}
          />
        </MDBox>
      </MDBox>
      {cost > 0 && installments > 1 && (
        <MDBox mb={2} mx={2}>
          <MDTypography variant="body2">Linha do tempo</MDTypography>
          <MDBox style={{ maxHeight: 210, overflowX: "hidden", overflowY: "auto" }}>
            {getTimeLine()}
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
}

PaymentMethod.defaultProps = {
  disabled: false,
};

PaymentMethod.propTypes = {
  cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  installments: PropTypes.number.isRequired,
  setInstallments: PropTypes.func.isRequired,
  paymentDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  setPaymentDate: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
};

export default PaymentMethod;
