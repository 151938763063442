import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Icon,
  Grid,
  Modal,
  TextField,
  Tooltip,
  IconButton,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { toast } from "react-toastify";

import MDBox from "components/CTFramework/MDBox";
import MDInput from "components/CTFramework/MDInput";
import MDTypography from "components/CTFramework/MDTypography";
import MDButton from "components/CTFramework/MDButton";
import { AxiosContext } from "context";
import MaskedTextField from "components/CTFramework/MaskedTextField";
import NewClient from "components/NewClient";
import { DatePicker } from "@mui/x-date-pickers";
import DataTable from "components/DataTable";

function NewEmission({ isOpen, onClose }) {
  const { authAxios } = useContext(AxiosContext);

  const [isOpenNewClient, setIsOpenNewClient] = useState(false);

  const [clients, setClients] = useState([]);
  const [dateIssued, setDateIssued] = useState(new Date());
  const [document, setDocument] = useState("");
  const [locator, setLocator] = useState("");
  const [passenger, setPassenger] = useState();
  const [passengerName, setPassengerName] = useState("");
  const [programs, setPrograms] = useState([]);
  const [rows, setRows] = useState([]);
  const [saving, setSaving] = useState(false);
  const [selectedClient, setSelectedClient] = useState({ id: "" });
  const [selectedProgram, setSelectedProgram] = useState({ id: "" });

  const columns = [
    { Header: "Documento", accessor: "documentView", align: "left" },
    { Header: "Passageiro", accessor: "passenger", width: "400px", align: "left" },
    { Header: "", accessor: "action", align: "right", width: "5px" },
  ];

  const addPassenger = () => {
    if (document !== "" && passengerName !== "") {
      if (rows.filter((r) => r.document === document).length > 0) {
        toast.error("Documento já informado!");
        return;
      }
      setRows([
        ...rows,
        {
          id: passenger?.id,
          document,
          name: passengerName,
          passenger: (
            <MDTypography variant="button" fontWeight="medium">
              {passengerName}
            </MDTypography>
          ),
          documentView: (
            <MDTypography variant="button" fontWeight="medium">
              {document}
            </MDTypography>
          ),
          action: (
            <MDBox display="flex" flexDirection="row">
              <Tooltip title="Remover passageiro">
                <IconButton onClick={() => setRows(rows.filter((r) => r.document || document))}>
                  <Icon color="error">delete</Icon>
                </IconButton>
              </Tooltip>
            </MDBox>
          ),
        },
      ]);
      setDocument("");
      setPassenger();
      setPassengerName("");
    } else {
      toast.error("Preencha os campos obrigatórios!");
    }
  };

  const save = async () => {
    if (selectedProgram?.id !== "" && selectedClient?.id !== "" && rows.length > 0) {
      try {
        setSaving(true);
        const response = await authAxios.post("/rewards/v1/ticket", {
          client: { id: selectedClient.id },
          dateIssued,
          locator,
          program: { id: selectedProgram.id, statistics: { id: selectedProgram.statistics.id } },
          passengers: rows.map((r) => ({ id: r.id, name: r.name, document: r.document })),
        });

        const { id } = response.data;

        onClose(true, id);
        fetchPrograms();
        toast.success("Emissão realizada com sucesso!");
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setSaving(false);
      }
    } else {
      toast.error("Preencha os campos obrigatórios!");
    }
  };

  useEffect(() => {
    if (isOpen) {
      setDateIssued(new Date());
      setDocument("");
      setLocator("");
      setPassenger(null);
      setPassengerName("");
      setRows([]);
      setSelectedClient("");
      setSelectedProgram({ id: "" });
    }
  }, [isOpen]);

  const checkPassenger = () => {
    authAxios.get(`/crm/v1/passengers?document=${document}`).then((response) => {
      if (response.data.length === 1) {
        setPassenger(response.data[0]);
        setPassengerName(response.data[0].name);
      } else {
        setPassenger(null);
        setPassengerName("");
      }
    });
  };

  const fetchClients = () => {
    authAxios.get("/crm/v1/clients").then((response) => {
      setClients(response.data);
    });
  };

  const fetchPrograms = () => {
    authAxios.get("/rewards/v1/programs").then((response) => {
      setPrograms(response.data);
    });
  };

  useEffect(() => {
    fetchPrograms();
    fetchClients();
  }, []);

  return (
    <Modal open={isOpen} onClose={() => onClose(false)}>
      <MDBox
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: 850,
        }}
      >
        <Card color="primary">
          <MDBox
            variant="contained"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Novo Emissão de Passagem
            </MDTypography>
          </MDBox>
          <MDBox display="flex" flexDirection="column">
            <MDBox px={2}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card id="create">
                    <MDBox pt={3} px={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                          <Autocomplete
                            disableClearable
                            value={selectedProgram}
                            options={programs}
                            getOptionLabel={(option) =>
                              option?.config?.name
                                ? `${option.config.name} - ${option.description}`
                                : ""
                            }
                            onChange={(event, newValue) => {
                              setSelectedProgram(newValue);
                            }}
                            fullWidth
                            renderInput={(params) => <MDInput {...params} label="Programa" />}
                            noOptionsText="Programa não encontrado"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <MDInput
                            type="number"
                            label="CPF's utilizados"
                            value={selectedProgram?.statistics?.emissions || 0 + rows.length}
                            disabled
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <DatePicker
                            label="Data do embarque"
                            inputFormat="dd/MM/yyyy"
                            value={dateIssued}
                            onChange={(newDate) => {
                              setDateIssued(newDate);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>

                    <MDBox py={3} px={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                          <MDInput
                            type="text"
                            label="Localizador"
                            fullWidth
                            value={locator}
                            onChange={(e) => setLocator(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={8}>
                          <MDBox display="flex" flexDirection="row" justifyContent="space-between">
                            <Autocomplete
                              disableClearable
                              value={selectedClient}
                              options={clients}
                              getOptionLabel={(option) =>
                                option?.name
                                  ? `${option.name} ${option.document && ` - ${option.document}`}`
                                  : ""
                              }
                              onChange={(event, newValue) => {
                                setSelectedClient(newValue);
                              }}
                              fullWidth
                              renderInput={(params) => <MDInput {...params} label="Cliente" />}
                              noOptionsText="Cliente não encontrado"
                            />
                            <MDButton
                              variant="gradient"
                              color="info"
                              onClick={() => {
                                setIsOpenNewClient(true);
                              }}
                              sx={{ marginLeft: 1 }}
                            >
                              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                            </MDButton>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>

            <MDBox py={3} px={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={12}>
                  <Card id="passengers">
                    <MDBox py={3} px={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                          <MaskedTextField
                            name="document"
                            label="Documento"
                            fullWidth
                            value={document}
                            onAccept={(value) => {
                              setDocument(value);
                            }}
                            onChange={(e) => {
                              setDocument(e.target.value);
                            }}
                            onBlur={() => checkPassenger()}
                            mask={document.length > 9 ? "000.000.000-00" : null}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} md={4} lg={7}>
                          <MDInput
                            type="text"
                            label="Nome Passageiro"
                            fullWidth
                            value={passengerName}
                            onChange={(e) => setPassengerName(e.target.value)}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={4} lg={2} container justifyContent="flex-end">
                          <MDButton variant="gradient" color="light" onClick={addPassenger}>
                            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                            &nbsp;Incluir
                          </MDButton>
                        </Grid>
                      </Grid>
                    </MDBox>

                    <MDBox
                      component="ul"
                      display="flex"
                      flexDirection="column"
                      p={0}
                      m={0}
                      sx={{ listStyle: "none" }}
                    >
                      <DataTable
                        table={{ columns, rows }}
                        noEndBorder
                        entriesPerPage={false}
                        isSorted={false}
                        showTotalEntries={false}
                      />
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>

          <MDBox mt={4} mb={1} mr={1} ml={1} display="flex" justifyContent="space-between">
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => {
                onClose(false);
              }}
            >
              Voltar
            </MDButton>
            <MDButton variant="gradient" color="primary" onClick={save}>
              {saving ? (
                <MDBox display="flex" justifyContent="space-between" color="text">
                  <CircularProgress color="text" size={15} />
                  &nbsp;Salvando
                </MDBox>
              ) : (
                "Salvar"
              )}
            </MDButton>
          </MDBox>
        </Card>

        {isOpenNewClient && (
          <NewClient
            isOpen={isOpenNewClient}
            onClose={(reload, id) => {
              if (reload) {
                fetchClients();
                setSelectedClient(id);
              }
              setIsOpenNewClient(false);
            }}
          />
        )}
      </MDBox>
    </Modal>
  );
}

NewEmission.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewEmission;
