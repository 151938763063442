import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Card, CircularProgress, Grid, InputAdornment, Modal, TextField } from "@mui/material";
import { toast } from "react-toastify";

import MDBox from "components/CTFramework/MDBox";
import MDInput from "components/CTFramework/MDInput";
import MDTypography from "components/CTFramework/MDTypography";
import MDButton from "components/CTFramework/MDButton";
import { AxiosContext } from "context";
import PaymentMethod from "components/PaymentMethod";
import { addMonths } from "date-fns";

function NewBill({ isOpen, onClose, type, bill }) {
  const { authAxios } = useContext(AxiosContext);

  const [date, setDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [installments, setInstallments] = useState(1);
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [paymentMethod, setPaymentMethod] = useState("");
  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState(0);

  const save = async () => {
    if (value >= 0) {
      try {
        setSaving(true);
        const bills = [];
        for (let i = 0; i < installments; i += 1) {
          bills.push({
            date: addMonths(date, i),
            description,
            entry: i + 1,
            entries: installments,
            paymentMethod,
            type,
            value: value / installments,
          });
        }
        await authAxios.post("/financial/v1/bills", bills);

        onClose(true);
        toast.success("Lancamento realizado com sucesso!");
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setSaving(false);
      }
    } else {
      toast.error("Preencha os campos obrigatórios!");
    }
  };

  useEffect(() => {
    if (isOpen) {
      setValue(bill?.value || 0);
      setDate(new Date());
      setDescription(bill?.description || "");
      setInstallments(bill?.installmentse || 1);
      setPaymentDate(bill?.paymentDate || new Date());
      setPaymentMethod(bill?.paymentMethod || "");
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={() => onClose(false)}>
      <MDBox
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: 300,
        }}
      >
        <Card color="primary">
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              {type === "outcoming" ? "Nova despesa" : "Nova receita"}
            </MDTypography>
          </MDBox>

          <MDBox display="flex" flexDirection="row">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox display="flex" flexDirection="column" mt={4}>
                  <MDBox mb={2} mx={2}>
                    <DatePicker
                      label="Data da transação"
                      inputFormat="dd/MM/yyyy"
                      value={date}
                      onChange={(newDate) => {
                        setDate(newDate);
                        setPaymentDate(newDate);
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </MDBox>

                  <MDBox mb={2} mx={2}>
                    <MDInput
                      type="number"
                      label="Valor"
                      fullWidth
                      value={value}
                      error={value < 0}
                      onChange={(e) => setValue(Number(e.target.value))}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                  </MDBox>

                  <MDBox mb={2} mx={2}>
                    <MDInput
                      type="text"
                      label="Descrição"
                      fullWidth
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox display="flex" flexDirection="column" mt={4}>
                  <PaymentMethod
                    cost={value}
                    installments={installments}
                    setInstallments={setInstallments}
                    paymentDate={paymentDate}
                    setPaymentDate={setPaymentDate}
                    paymentMethod={paymentMethod}
                    setPaymentMethod={setPaymentMethod}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox mt={4} mb={1} mr={1} ml={1} display="flex" justifyContent="space-between">
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => {
                onClose(false);
              }}
            >
              Voltar
            </MDButton>
            <MDButton variant="gradient" color="primary" onClick={save}>
              {saving ? (
                <MDBox display="flex" justifyContent="space-between" color="text">
                  <CircularProgress color="text" size={15} />
                  &nbsp;Salvando
                </MDBox>
              ) : (
                "Salvar"
              )}
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
  );
}

NewBill.defaultProps = {
  bill: null,
};

NewBill.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["incoming", "outcoming"]).isRequired,
  bill: PropTypes.object,
};

export default NewBill;
