import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Backdrop, Card, CircularProgress, Icon, MenuItem } from "@mui/material";
import { addMonths, parseISO } from "date-fns";

import MDBox from "components/CTFramework/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SubscriptionCard from "components/Cards/SubscriptionCard";
import { AxiosContext } from "context";
import MDButton from "components/CTFramework/MDButton";
import MDSelect from "components/CTFramework/MDSelect";
import StatusEnum from "enuns/status";
import { useIntl } from "react-intl";

import NewSubscription from "pages/subscriptions/components/NewSubscription";
import MDTypography from "components/CTFramework/MDTypography";

const newSubscription = {
  dateEnd: addMonths(new Date(), 12),
  dateStart: new Date(),
  payment: {
    dateStart: new Date(),
    installments: 1,
    method: "",
    value: 0,
  },
  program: { id: "" },
  points: 0,
  recurrence: "",
  rewards: [],
  status: "active",
  value: 0,
};

function Subscriptions() {
  const { authAxios } = useContext(AxiosContext);
  const { formatMessage } = useIntl();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState(StatusEnum.Active);
  const [subscription, setSubscription] = useState(newSubscription);
  const [subscriptions, setSubscriptions] = useState([]);

  async function fetchData() {
    setLoading(true);
    const response = await authAxios.get(`/rewards/v1/subscriptions?status=${statusFilter}`);

    setSubscriptions(response.data);
    setLoading(false);
  }
  useEffect(() => {
    fetchData();
  }, [statusFilter]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card id="create-subscription">
                <MDBox display="flex" flexDirection="row" justifyContent="space-between">
                  <MDBox py={2} px={2} display="flex" flexDirection="column">
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      color="text"
                      borderRadius="xl"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="2rem"
                      mt={-3}
                      mb={3}
                    >
                      <MDTypography component="p" variant="button" color="white" display="flex">
                        Filtros&nbsp;
                      </MDTypography>
                    </MDBox>
                    <MDSelect
                      value={statusFilter}
                      onChange={(e) => setStatusFilter(e.target.value)}
                      label="Status"
                      style={{ width: 150 }}
                    >
                      {Object.keys(StatusEnum).map((key) => (
                        <MenuItem value={StatusEnum[key]} key={key}>
                          {formatMessage({ id: StatusEnum[key] })}
                        </MenuItem>
                      ))}
                    </MDSelect>
                  </MDBox>
                  <MDBox py={2} px={2} display="flex" justifyContent="right" alignItems="center">
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={() => {
                        setSubscription(newSubscription);
                        setIsOpenModal(true);
                      }}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;Nova Assinatura
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {!loading && (
              <Grid item xs={12}>
                <MDBox p={2}>
                  <Grid container spacing={3}>
                    {subscriptions.map((item) => (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={3}
                        key={item.id}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setSubscription({
                            ...item,
                            dateStart: parseISO(item.dateStart),
                            dateEnd: parseISO(item.dateEnd),
                          });
                          setIsOpenModal(true);
                        }}
                      >
                        <SubscriptionCard
                          color="light"
                          subscription={item}
                          points={{
                            color: "success",
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </MDBox>
              </Grid>
            )}
          </Grid>
        </MDBox>
      </MDBox>
      <NewSubscription
        subscription={subscription}
        setSubscription={setSubscription}
        isOpenModal={isOpenModal}
        onClose={(reload) => {
          if (reload) {
            fetchData();
          }
          setIsOpenModal(false);
        }}
      />
    </DashboardLayout>
  );
}

export default Subscriptions;
