import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Card, Icon } from "@mui/material";
import { format, parseISO } from "date-fns";

import MDBox from "components/CTFramework/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import CreditCard from "components/Cards/CreditCard";
import { AxiosContext } from "context";
import MDButton from "components/CTFramework/MDButton";

import NewCard from "./components/NewCard";

function Cards() {
  const { authAxios } = useContext(AxiosContext);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [card, setCard] = useState({});
  const [cards, setCards] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await authAxios.get("/user/v1/cards?status=active");

      setCards(response.data);
    }
    if (!isOpenModal) {
      fetchData();
    }
  }, [isOpenModal]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card id="create-program">
                <MDBox py={2} px={2} display="flex" justifyContent="right" alignItems="center">
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={() => {
                      setCard({
                        brand: "",
                        expires: new Date(),
                        name: "",
                        number: "**** **** **** ",
                        status: "active",
                      });
                      setIsOpenModal(true);
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;Novo Cartão
                  </MDButton>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <MDBox p={2}>
                <Grid container spacing={3}>
                  {cards.map((item) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      key={item.id}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setCard(item);
                        setIsOpenModal(true);
                      }}
                    >
                      <CreditCard
                        brand={item.brand}
                        color="dark"
                        number={item.number}
                        holder={item.name}
                        expires={format(parseISO(item.expires), "MM/yy")}
                        closeDay={item.closeDay}
                        payDay={item.payDay}
                      />
                    </Grid>
                  ))}
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <NewCard
        card={card}
        setCard={setCard}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
    </DashboardLayout>
  );
}

export default Cards;
