import { forwardRef } from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel } from "@mui/material";
import { useThemeController } from "context";

import MDSelectRoot from "./MDSelectRoot";

const MDSelect = forwardRef(({ error, success, disabled, ...rest }, ref) => {
  const [controller] = useThemeController();
  const { darkMode } = controller;

  return (
    <FormControl fullWidth={rest.fullWidth}>
      <InputLabel>{rest.label}</InputLabel>
      <MDSelectRoot {...rest} ref={ref} ownerState={{ error, success, disabled, darkMode }} />
    </FormControl>
  );
});

MDSelect.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

MDSelect.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MDSelect;
