import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/CTFramework/MDBox";
import DataTable from "components/DataTable";

function ListTickets({ rows }) {
  const columns = [
    { Header: "Programa", accessor: "program", width: "150px", align: "left" },
    { Header: "Passageiros", accessor: "passengers", width: "200px", align: "left" },
    { Header: "Data", accessor: "date", align: "right" },
    { Header: "Localizador", accessor: "locator", align: "right" },
  ];

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} pb={2} px={2}>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <DataTable
            table={{ columns, rows }}
            noEndBorder
            entriesPerPage={false}
            isSorted={false}
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

ListTickets.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default ListTickets;
