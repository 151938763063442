import { createContext, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

const AuthContext = createContext();

function useAuth() {
  return useContext(AuthContext);
}

function AuthProvider({ children }) {
  const [authState, setAuthState] = useState({
    accessToken: null,
    refreshToken: null,
    authenticated: null,
  });
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function logout() {
    setAuthState({
      accessToken: null,
      refreshToken: null,
      authenticated: false,
    });

    localStorage.setItem("app_config", {});
  }

  const getAccessToken = () => authState.accessToken;

  function getUser() {
    return currentUser;
  }

  useEffect(() => {
    try {
      const appConfig = localStorage.getItem("app_config");
      const jwt = JSON.parse(appConfig);
      setAuthState({
        accessToken: jwt.token.accessToken || null,
        refreshToken: jwt.token.refreshToken || null,
        authenticated: jwt.token.accessToken !== null,
      });
      setCurrentUser(jwt.user);
    } catch (err) {
      setAuthState({
        accessToken: null,
        refreshToken: null,
        authenticated: false,
      });
      setCurrentUser();
    }
    setLoading(false);
  }, []);

  const value = useMemo(() => ({
    authState,
    getAccessToken,
    setAuthState,
    getUser,
    logout,
    setCurrentUser,
  }));

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthProvider, useAuth };
