import { useContext, useState } from "react";
import { AxiosContext, useAuth } from "context";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";

import MDBox from "../../../components/CTFramework/MDBox";
import MDTypography from "../../../components/CTFramework/MDTypography";
import MDInput from "../../../components/CTFramework/MDInput";
import MDButton from "../../../components/CTFramework/MDButton";
import BasicLayout from "../components/BasicLayout";
import bgImage from "../../../assets/images/bg-sign-up-cover.jpeg";
import MDSnackbar from "../../../components/CTFramework/MDSnackbar";

function SingUp() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const closeError = () => setErrorMessage();

  const { setAuthState, setCurrentUser } = useAuth();
  const { publicAxios } = useContext(AxiosContext);
  const navigate = useNavigate();

  const handleSingUp = async () => {
    try {
      const response = await publicAxios.post("/auth/v1/register", {
        name,
        password,
        username: email,
      });

      setAuthState({
        accessToken: response.data.token.accessToken,
        refreshToken: response.data.token.refreshToken,
        authenticated: true,
      });

      localStorage.setItem("app_config", JSON.stringify(response.data));

      setCurrentUser(response.data.user);
      navigate("/", { replace: true });
    } catch (err) {
      setErrorMessage(
        err.response.data.statusCode === 422
          ? "Já existe uma conta criada com este e-mail!"
          : "Ocorreu um erro inesperado, estamos trabalhando para resolver."
      );
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Crie sua conta
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Informe seu e-mail e uma senha
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Nome"
                variant="standard"
                fullWidth
                onChange={(e) => setName(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="E-mail"
                variant="standard"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Senha"
                variant="standard"
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSingUp}>
                Criar
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Já possui uma conta?{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Login
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>

      <MDSnackbar
        color="error"
        icon="warning"
        title="Não foi possível criar sua conta"
        content={errorMessage}
        dateTime="1 seg"
        open={!!errorMessage}
        onClose={closeError}
        close={closeError}
        bgWhite
      />
    </BasicLayout>
  );
}

export default SingUp;
