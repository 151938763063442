import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

import MDBox from "../CTFramework/MDBox";
import MDTypography from "../CTFramework/MDTypography";

function Breadcrumbs({ icon, title, route, light }) {
  const routes = route.slice(0, -1);

  const routesTranslated = [
    {
      name: "Dashboard",
      key: "dashboard",
    },
    {
      name: "Cartões",
      key: "cards",
    },
    {
      name: "Assinaturas",
      key: "subscriptions",
    },
    {
      name: "Programas",
      key: "programs",
    },
    {
      name: "Transações",
      key: "transactions",
    },
    {
      name: "Cotação Milhas",
      key: "quotes",
    },
    {
      name: "Gráfico",
      key: "chart",
    },
    {
      name: "Financeiro",
      key: "financial",
    },
    {
      name: "Receitas",
      key: "incoming",
    },
    {
      name: "Despesas",
      key: "outcoming",
    },
    {
      name: "Relatórios",
      key: "reports",
    },
    {
      name: "Fluxo de Caixa",
      key: "cfc",
    },
    {
      name: "Vendas",
      key: "sales",
    },
    {
      name: "title",
      title: "CRM",
    },
    {
      name: "Clientes",
      key: "clients",
    },
    {
      name: "Cliente",
      key: "client",
    },
    {
      name: "Sua conta",
      key: "conta",
    },
    {
      name: "Passagens emitidas",
      key: "emissoes",
    },
    {
      name: "Calculadoras",
      key: "calculators",
    },
    {
      name: "Suporte",
      key: "support",
    },
    {
      name: "Seu Chamado",
      key: "issue",
    },
    {
      name: "Notificações",
      key: "notifications",
    },
  ];

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to="/">
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </MDTypography>
        </Link>
        {routes.map((el) => (
          <MDTypography
            component="span"
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
            key={el}
          >
            {routesTranslated.find((r) => r.key === el)?.name}
          </MDTypography>
        ))}
        <MDTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {routesTranslated.find((r) => r.key === title)?.name}
        </MDTypography>
      </MuiBreadcrumbs>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
