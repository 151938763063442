import { useState, useEffect, useContext } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Icon, IconButton, Menu, Toolbar, Tooltip } from "@mui/material";
import { AxiosContext, useAuth } from "context";
import MDBadge from "components/CTFramework/MDBadge";
import NotificationModal from "components/NotificationModal";

import MDBox from "../../CTFramework/MDBox";
import Breadcrumbs from "../../Breadcrumbs";
import NotificationItem from "../../Items/NotificationItem";
import { navbar, navbarContainer, navbarRow, navbarIconButton, navbarMobileMenu } from "./styles";
import {
  useThemeController,
  setTransparentNavbar,
  setMiniSidenav,
} from "../../../context/ThemeProvider";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useThemeController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState({});

  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleLogout = () => {
    logout();
    navigate("/login", { replace: true });
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {notifications.slice(0, 5).map((item) => (
        <NotificationItem
          key={item.id}
          onClick={() => {
            setSelectedNotification(item);
            setIsOpenNotification(true);
            // eslint-disable-next-line no-param-reassign
            item.read = true;
          }}
          icon={<Icon>{item.read ? "check" : "email"}</Icon>}
          title={item.message}
        />
      ))}

      {notifications.length > 0 && (
        <NotificationItem
          icon={<Icon>more</Icon>}
          onClick={() => {
            navigate("/notifications", { replace: true });
          }}
          title="Veja mais"
        />
      )}

      {notifications.length === 0 && (
        <NotificationItem
          icon={<Icon>sentiment_very_satisfied</Icon>}
          title="Nenhuma notificação"
        />
      )}
    </Menu>
  );

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const { authAxios } = useContext(AxiosContext);
  const [notifications, setNotifications] = useState([]);

  const fetchNotifications = async () => {
    authAxios.get("/user/v1/notifications").then((response) => {
      setNotifications(response.data);
    });
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <Tooltip title="Meus dados">
                <Link to="/conta">
                  <IconButton sx={navbarIconButton} size="small" disableRipple>
                    <Icon sx={iconsStyle}>account_circle</Icon>
                  </IconButton>
                </Link>
              </Tooltip>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

              <Tooltip title="Notificações">
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <MDBadge
                    badgeContent={notifications?.filter((item) => !item.read).length}
                    size="xs"
                  >
                    <Icon sx={iconsStyle}>notifications</Icon>
                  </MDBadge>
                </IconButton>
              </Tooltip>
              <Tooltip title="Sair">
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="logout-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleLogout}
                >
                  <Icon sx={iconsStyle}>logout</Icon>
                </IconButton>
              </Tooltip>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>

      <NotificationModal
        isOpen={isOpenNotification}
        onClose={(remove) => {
          if (remove) {
            setNotifications(notifications.filter((n) => n.id !== selectedNotification.id));
          }
          setIsOpenNotification(false);
        }}
        notification={selectedNotification}
      />
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
