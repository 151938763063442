import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import MDBox from "components/CTFramework/MDBox";
import MDTypography from "components/CTFramework/MDTypography";
import { Avatar } from "@mui/material";
import { differenceInMonths, parseISO } from "date-fns";
import Formatters from "helpers/formatters";
import Utils from "helpers/utils";
import RecurrenceEnum from "enuns/recurrence";

function SubscriptionCard({ color, points, subscription }) {
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Avatar
            variant="rounded"
            sizes="small"
            src={`img/icons/programs/${subscription.program.config.icon}`}
          />
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography variant="h5" fontWeight="light" color="text">
            {subscription.program.config.name}
          </MDTypography>
          <MDTypography variant="h6" fontWeight="light" color="text">
            &nbsp;{subscription.program.description}
          </MDTypography>
          <MDTypography component="span" variant="button" fontWeight="light" color="text">
            Vencimento em {Formatters.dateShort(subscription.dateEnd)}
          </MDTypography>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox pb={1} px={2} display="flex" justifyContent="right">
        <MDTypography component="p" variant="button" color="text" display="flex">
          <MDTypography component="span" variant="button" fontWeight="bold" color="text">
            {Formatters.number(
              subscription.points *
                differenceInMonths(
                  parseISO(subscription.dateEnd),
                  parseISO(subscription.dateStart)
                ) +
                subscription.rewards.reduce((a, c) => a + c.points, 0)
            )}
          </MDTypography>
          &nbsp;pts
        </MDTypography>
      </MDBox>
      <MDBox px={2} display="flex" justifyContent="right">
        <MDTypography component="p" variant="button" color="text" display="flex">
          Preço medio&nbsp;
        </MDTypography>
      </MDBox>
      <MDBox pb={1} px={2} display="flex" justifyContent="right">
        <MDTypography component="span" variant="button" fontWeight="bold" color={points.color}>
          {Formatters.currency(
            Utils.getCostInUnit(
              (subscription.recurrence === RecurrenceEnum.Monthly
                ? subscription.value *
                  differenceInMonths(
                    parseISO(subscription.dateEnd),
                    parseISO(subscription.dateStart)
                  )
                : subscription.value) /
                (subscription.points *
                  differenceInMonths(
                    parseISO(subscription.dateEnd),
                    parseISO(subscription.dateStart)
                  ) +
                  subscription.rewards.reduce((a, c) => a + c.points, 0)),
              subscription.program.config.unitMeasure
            )
          )}
        </MDTypography>
      </MDBox>
      <MDBox px={2} display="flex" justifyContent="right">
        <MDTypography component="p" variant="button" color="text" display="flex">
          Valor
        </MDTypography>
      </MDBox>
      <MDBox pb={1} px={2} display="flex" justifyContent="right">
        <MDTypography component="span" variant="button" fontWeight="bold" color={points.color}>
          {Formatters.currency(subscription.value)}
        </MDTypography>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ProgramCard
SubscriptionCard.defaultProps = {
  color: "info",
  points: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ProgramCard
SubscriptionCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  points: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
  }),
  subscription: PropTypes.object.isRequired,
};

export default SubscriptionCard;
