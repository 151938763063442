import React, { createContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import PropTypes from "prop-types";

import { useAuth } from "./AuthProvider";

const AxiosContext = createContext();
const { Provider } = AxiosContext;

function AxiosProvider({ children }) {
  const authContext = useAuth();
  const navigate = useNavigate();

  let refreshedToken = null;

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_HOST,
  });

  const publicAxios = axios.create({
    baseURL: process.env.REACT_APP_HOST,
  });

  authAxios.interceptors.request.use(
    (config) => {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${refreshedToken || authContext.getAccessToken()}`;

      return config;
    },
    (error) => Promise.reject(error)
  );

  const refreshAuthLogic = (failedRequest) => {
    const data = {
      refreshToken: authContext.authState.refreshToken,
    };

    const options = {
      method: "POST",
      data,
      url: `${process.env.REACT_APP_HOST}/auth/v1/refresh`,
    };

    return axios(options)
      .then(async (tokenRefreshResponse) => {
        // eslint-disable-next-line no-param-reassign
        failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.token.accessToken}`;

        refreshedToken = tokenRefreshResponse.data.token.accessToken;

        authContext.setAuthState({
          refreshToken: authContext.authState.refreshToken,
          authenticated: true,
          accessToken: tokenRefreshResponse.data.token.accessToken,
        });

        localStorage.setItem(
          "app_config",
          JSON.stringify({
            user: authContext.getUser(),
            token: {
              ...authContext.authState,
              accessToken: tokenRefreshResponse.data.token.accessToken,
            },
          })
        );
        return Promise.resolve();
      })
      .catch(() => {
        authContext.setAuthState({
          accessToken: null,
          refreshToken: null,
          authenticated: false,
        });

        navigate("/login", { replace: true });
      });
  };

  createAuthRefreshInterceptor(authAxios, refreshAuthLogic, {
    pauseInstanceWhileRefreshing: false,
  });

  return (
    <Provider
      value={{
        authAxios,
        publicAxios,
      }}
    >
      {children}
    </Provider>
  );
}

AxiosProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AxiosContext, AxiosProvider };
