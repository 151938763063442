import { useContext, useState } from "react";
import Card from "@mui/material/Card";
import { AxiosContext } from "context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import MDBox from "../../../components/CTFramework/MDBox";
import MDTypography from "../../../components/CTFramework/MDTypography";
import MDInput from "../../../components/CTFramework/MDInput";
import MDButton from "../../../components/CTFramework/MDButton";
import BasicLayout from "../components/BasicLayout";
import bgImage from "../../../assets/images/bg-reset-cover.jpeg";

function ConfirmResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { publicAxios } = useContext(AxiosContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleResetWithMail = async () => {
    try {
      await publicAxios.post("/auth/v1/reset", {
        id: searchParams.get("id"),
        password,
        token: searchParams.get("token"),
      });

      navigate("/login", { replace: true });
      toast.success("Sua senha foi alterada com sucesso!");
    } catch (err) {
      toast.warn("Por favor verifique os dados informados!");
    }
  };

  return (
    <BasicLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Informe sua nova senha
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="password"
                label="Nova Senha"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox mb={4}>
              <MDInput
                type="password"
                label="Confirma a Senha"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleResetWithMail}>
                Confirmar
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ConfirmResetPassword;
