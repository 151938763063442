import { useContext, useEffect, useState } from "react";
import MDBox from "components/CTFramework/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDButton from "components/CTFramework/MDButton";
import { toast } from "react-toastify";
import MDTypography from "components/CTFramework/MDTypography";
import { AxiosContext } from "context";
import Icon from "@mui/material/Icon";
import { Backdrop, Card, CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import MDInput from "components/CTFramework/MDInput";
import { useConfirm } from "material-ui-confirm";
import { Link } from "react-router-dom";
import { parseISO } from "date-fns";
import Formatters from "helpers/formatters";
import { useIntl } from "react-intl";

import List from "./components/List";

function Issues() {
  const { authAxios } = useContext(AxiosContext);
  const confirm = useConfirm();
  const { formatMessage } = useIntl();

  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const fetchIssues = () => {
    setLoading(true);
    authAxios
      .get(`/support/v1/issues?title=${title}&status=${status} `)
      .then((response) => {
        parseRows(response.data);
      })
      .finally(() => setLoading(false));
  };

  const remove = async (id) => {
    setLoading(true);
    try {
      confirm({
        description: "Deseja realmente apagar esse chamado?",
      }).then(async () => {
        await authAxios.delete(`/support/v1/issues/${id}`);

        fetchIssues();
      });
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message);
    }
  };

  const parseRows = (items) => {
    setRows(
      items.map((item) => ({
        title: (
          <MDTypography variant="button" fontWeight="regular" sx={{ width: 200 }}>
            {item.title}
          </MDTypography>
        ),
        status: (
          <MDTypography variant="button" fontWeight="regular">
            {formatMessage({ id: item.status })}
          </MDTypography>
        ),
        createdAt: (
          <MDTypography variant="caption" fontWeight="regular">
            {Formatters.dateTime(parseISO(item.createdAt))}
          </MDTypography>
        ),
        updatedAt: (
          <MDTypography variant="button" fontWeight="regular">
            {Formatters.dateTime(parseISO(item.updatedAt))}
          </MDTypography>
        ),
        action: (
          <MDBox display="flex" flexDirection="row">
            <Tooltip title="Visualisar">
              <Link to="/issue" state={{ id: item.id }}>
                <IconButton>
                  <Icon color="info">contact_page</Icon>
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title="Apagar chamado">
              <IconButton onClick={() => remove(item.id)}>
                <Icon color="error">delete</Icon>
              </IconButton>
            </Tooltip>
          </MDBox>
        ),
      }))
    );
  };

  useEffect(() => {
    fetchIssues();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Card sx={{ height: "100%" }}>
            <MDBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="5rem"
              height="3rem"
              mt={-3}
            >
              <MDTypography variant="caption" fontWeight="regular">
                Filtros
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" py={3} px={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                  <MDInput
                    type="text"
                    label="Status"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    style={{ minWidth: 250 }}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <MDInput
                    type="text"
                    label="Título"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    style={{ minWidth: 250 }}
                  />
                </Grid>
              </Grid>
            </MDBox>

            <MDBox py={3} px={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={12}>
                  <MDBox display="flex" justifyContent="flex-end">
                    <MDButton
                      color="error"
                      onClick={() => {
                        setTitle("");
                        setStatus("");
                        setRows([]);
                      }}
                    >
                      <Icon>clear</Icon>&nbsp;Limpar
                    </MDButton>
                    <MDButton
                      color="info"
                      onClick={() => {
                        fetchIssues();
                      }}
                      style={{ marginLeft: "1em" }}
                    >
                      <Icon>search</Icon>&nbsp;Pesquisar
                    </MDButton>

                    <Link to="/issue">
                      <MDButton variant="gradient" color="dark" style={{ marginLeft: "1em" }}>
                        &nbsp;Abrir Chamado
                      </MDButton>
                    </Link>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>

        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <List rows={rows} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Issues;
