import { useContext, useEffect, useState } from "react";
import MDBox from "components/CTFramework/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { toast } from "react-toastify";
import MDTypography from "components/CTFramework/MDTypography";
import { AxiosContext } from "context";
import Icon from "@mui/material/Icon";
import {
  Backdrop,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { parseISO } from "date-fns";
import Formatters from "helpers/formatters";

import List from "./components/List";

function Notifications() {
  const { authAxios } = useContext(AxiosContext);

  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [rows, setRows] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  const fetchNotifications = () => {
    setLoading(true);
    authAxios
      .get(`/user/v1/notifications`)
      .then((response) => {
        setNotifications(response.data);
      })
      .finally(() => setLoading(false));
  };

  const remove = async () => {
    setLoading(true);
    try {
      await authAxios.post(
        `/user/v1/notifications/delete`,
        selecteds.map((s) => ({ id: s.id }))
      );

      fetchNotifications();
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message);
    }
  };

  const update = async (read) => {
    try {
      await authAxios.patch(
        `/user/v1/notifications`,
        selecteds.map((s) => ({ id: s.id, read }))
      );
      setSelecteds(selecteds.map((s) => ({ id: s.id, read })));
      fetchNotifications();
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    setRows(
      notifications.map((item) => ({
        select: (
          <Checkbox
            checked={selecteds.some((s) => s.id === item.id)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelecteds((current) => [...current, item]);
              } else {
                setSelecteds((current) => current.filter((s) => s.id !== item.id));
              }
            }}
          />
        ),
        message: (
          <MDTypography
            variant="button"
            fontWeight={item.read ? "regular" : "bold"}
            sx={{ width: 600 }}
          >
            {item.message}
          </MDTypography>
        ),
        createdAt: (
          <MDTypography variant="caption" fontWeight="regular">
            {Formatters.dateTime(parseISO(item.createdAt))}
          </MDTypography>
        ),
      }))
    );
  }, [notifications, selecteds]);
  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Card sx={{ height: "100%" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  mt={3}
                  mx={2}
                  pl={4.8}
                  pb={1}
                  pt={1}
                  sx={{ borderBottom: "1px solid", backgroundColor: "#f0f2f5", borderRadius: 1 }}
                >
                  <Tooltip title="Selecionar todas">
                    <Checkbox
                      checked={
                        selecteds.length === notifications.length && notifications.length > 0
                      }
                      onChange={(e) => {
                        setSelecteds(e.target.checked ? notifications : []);
                      }}
                    />
                  </Tooltip>
                  {selecteds.length > 0 && (
                    <MDBox ml={2}>
                      <Tooltip
                        title={
                          selecteds.some((n) => n.read)
                            ? "Marcar como não lido"
                            : "Marcar como lido"
                        }
                      >
                        <IconButton onClick={() => update(!selecteds.some((n) => n.read))}>
                          <Icon color="info">
                            {selecteds.some((n) => n.read) ? "mark_as_unread" : "mark_email_read"}
                          </Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Apagar">
                        <IconButton onClick={() => remove()}>
                          <Icon color="error">delete</Icon>
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  )}
                </MDBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <List rows={rows} />
              </Grid>
            </Grid>
          </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Notifications;
