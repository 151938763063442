import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Autocomplete,
  Avatar,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";

import MDBox from "components/CTFramework/MDBox";
import MDInput from "components/CTFramework/MDInput";
import MDTypography from "components/CTFramework/MDTypography";
import MDButton from "components/CTFramework/MDButton";
import { AxiosContext } from "context";
import MDSelect from "components/CTFramework/MDSelect";
import ProgramDetails from "components/ProgramDetails";

function NewTransfer({ isOpen, onClose }) {
  const { authAxios } = useContext(AxiosContext);

  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState(new Date());
  const [parity, setParity] = useState(1);
  const [programs, setPrograms] = useState([]);
  const [rewards, setRewards] = useState(0);
  const [rewardsBack, setRewardsBack] = useState(0);
  const [saving, setSaving] = useState(false);
  const [selectedProgramOrigin, setSelectedProgramOrigin] = useState({ id: "" });
  const [selectedProgramTarget, setSelectedProgramTarget] = useState({ id: "" });
  const [type, setType] = useState("normal");

  const save = async () => {
    if (amount > selectedProgramOrigin.statistics.amountInStock) {
      toast.error("Quantidade insuficiente para transferir");
      return;
    }
    if (
      selectedProgramOrigin.id !== "" &&
      selectedProgramTarget.id !== "" &&
      amount / parity > 0 &&
      rewards >= 0
    ) {
      try {
        setSaving(true);
        await authAxios.post("/rewards/v1/transfer", {
          amount,
          date,
          parity,
          programOrigin: selectedProgramOrigin,
          programTarget: selectedProgramTarget,
          rewards,
          rewardsBack,
          type,
        });

        onClose(true);
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setSaving(false);
      }
    } else {
      toast.error("Preencha os campos obrigatórios");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await authAxios.get(`/rewards/v1/programs`);
      setPrograms(response.data);
    };

    if (isOpen) {
      fetchData();

      setAmount(0);
      setDate(new Date());
      setRewards(0);
      setRewardsBack(0);
      setSelectedProgramOrigin({ id: "" });
      setSelectedProgramTarget({ id: "" });
      setType("normal");
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={() => onClose(false)}>
      <MDBox
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: 300,
          maxHeight: "90%",
        }}
      >
        <Card color="primary">
          <MDBox
            variant={selectedProgramOrigin.id !== "" ? "contained" : "gradient"}
            bgColor={selectedProgramOrigin.id !== "" ? selectedProgramOrigin.config.color : "info"}
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Nova transferência de pontos
            </MDTypography>
          </MDBox>

          <MDBox
            style={{
              maxHeight: 700,
              overflow: "hidden",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <MDBox display="flex" flexDirection="row">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox display="flex" flexDirection="column">
                    <MDBox mb={2} mx={2} mt={1} display="flex" flexDirection="row">
                      <Autocomplete
                        disableClearable
                        value={selectedProgramOrigin}
                        options={programs}
                        getOptionLabel={(option) =>
                          option?.config?.name
                            ? `${option.config.name} - ${option.description}`
                            : ""
                        }
                        onChange={(event, newValue) => {
                          setSelectedProgramOrigin(newValue);
                        }}
                        fullWidth
                        renderInput={(params) => <MDInput {...params} label="Programa Origem" />}
                        noOptionsText="Programa não encontrado"
                      />

                      {selectedProgramOrigin?.config?.icon && (
                        <MDBox ml={1}>
                          <Avatar
                            variant="rounded"
                            sizes="small"
                            src={`img/icons/programs/${selectedProgramOrigin.config.icon}`}
                          />
                        </MDBox>
                      )}
                    </MDBox>

                    <MDBox mb={2} mx={2}>
                      <MDSelect
                        value={type}
                        error={!type}
                        onChange={(e) => setType(e.target.value)}
                        label="Tipo"
                        fullWidth
                      >
                        <MenuItem>Selecione</MenuItem>
                        <MenuItem value="normal">Normal</MenuItem>
                        <MenuItem value="boomerang">Boomerang</MenuItem>
                      </MDSelect>
                    </MDBox>

                    <MDBox mb={2} mx={2}>
                      <MDInput
                        type="number"
                        label="Quantidade"
                        fullWidth
                        value={amount}
                        error={amount < 0}
                        onChange={(e) => setAmount(Number(e.target.value))}
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                      />
                    </MDBox>

                    <MDBox mb={2} ml={2} mr={2} shadow="md" coloredShadow="light" borderRadius="lg">
                      <MDTypography
                        component="span"
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        style={{ marginLeft: 15 }}
                      >
                        Saldo do programa {selectedProgramOrigin?.config?.name}
                      </MDTypography>
                      {selectedProgramOrigin?.config?.icon && (
                        <ProgramDetails
                          amountInStock={selectedProgramOrigin.statistics.amountInStock}
                          costPerUnit={selectedProgramOrigin.statistics.costPerUnit}
                          unitMeasure={selectedProgramOrigin.config.unitMeasure}
                        />
                      )}
                    </MDBox>

                    <MDBox mb={2} ml={2} mr={2} shadow="md" coloredShadow="light" borderRadius="lg">
                      <MDTypography
                        component="span"
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        style={{ marginLeft: 15 }}
                      >
                        Saldo após a transferência
                      </MDTypography>
                      {selectedProgramOrigin?.config?.icon && (
                        <ProgramDetails
                          amountInStock={
                            type === "normal"
                              ? selectedProgramOrigin.statistics.amountInStock - amount
                              : selectedProgramOrigin.statistics.amountInStock -
                                amount +
                                amount * (rewardsBack / 100)
                          }
                          costPerUnit={selectedProgramOrigin.statistics.costPerUnit}
                          unitMeasure={selectedProgramOrigin.config.unitMeasure}
                        />
                      )}
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox display="flex" flexDirection="column" mt={1}>
                    <MDBox mb={2} mx={2} display="flex" flexDirection="row">
                      <Autocomplete
                        disableClearable
                        value={selectedProgramTarget}
                        options={programs}
                        getOptionLabel={(option) =>
                          option?.config?.name
                            ? `${option.config.name} - ${option.description}`
                            : ""
                        }
                        onChange={(event, newValue) => {
                          setSelectedProgramTarget(newValue);
                        }}
                        fullWidth
                        renderInput={(params) => <MDInput {...params} label="Programa Destino" />}
                        noOptionsText="Programa não encontrado"
                      />

                      {selectedProgramTarget?.config?.icon && (
                        <MDBox ml={1}>
                          <Avatar
                            variant="rounded"
                            sizes="small"
                            src={`img/icons/programs/${selectedProgramTarget.config.icon}`}
                          />
                        </MDBox>
                      )}
                    </MDBox>

                    <MDBox mb={2} mx={2}>
                      {type !== "boomerang" && (
                        <MDInput
                          type="number"
                          label="Paridade"
                          value={parity}
                          error={parity < 1}
                          onChange={(e) => setParity(Number(e.target.value))}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">: 1</InputAdornment>,
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          style={{ width: "48%", marginRight: 5 }}
                        />
                      )}

                      <MDInput
                        type="number"
                        label="Bônus"
                        value={rewards}
                        error={rewards < 0}
                        onChange={(e) => setRewards(Number(e.target.value))}
                        InputProps={{
                          endAdornment: <InputAdornment position="start">%</InputAdornment>,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        style={{ width: "48%" }}
                      />

                      {type === "boomerang" && (
                        <MDInput
                          type="number"
                          label="Bônus Volta"
                          value={rewardsBack}
                          error={rewardsBack < 0}
                          onChange={(e) => setRewardsBack(Number(e.target.value))}
                          InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          style={{ width: "50%", marginLeft: 5 }}
                        />
                      )}
                    </MDBox>

                    <MDBox mb={2} mx={2}>
                      <DatePicker
                        label="Data da transferência"
                        inputFormat="dd/MM/yyyy"
                        value={date}
                        onChange={setDate}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                      />
                    </MDBox>

                    <MDBox mb={2} ml={2} mr={2} shadow="md" coloredShadow="light" borderRadius="lg">
                      <MDTypography
                        component="span"
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        style={{ marginLeft: 15 }}
                      >
                        Saldo do programa {selectedProgramTarget?.config?.name}
                      </MDTypography>
                      {selectedProgramTarget?.config?.icon && (
                        <ProgramDetails
                          amountInStock={selectedProgramTarget.statistics.amountInStock}
                          costPerUnit={selectedProgramTarget.statistics.costPerUnit}
                          unitMeasure={selectedProgramTarget.config.unitMeasure}
                        />
                      )}
                    </MDBox>

                    <MDBox mb={2} ml={2} mr={2} shadow="md" coloredShadow="light" borderRadius="lg">
                      <MDTypography
                        component="span"
                        variant="button"
                        fontWeight="bold"
                        color="text"
                        style={{ marginLeft: 15 }}
                      >
                        Saldo após a transferência
                      </MDTypography>
                      {selectedProgramTarget?.config?.icon && (
                        <ProgramDetails
                          amountInStock={
                            selectedProgramTarget.statistics.amountInStock +
                            amount / parity +
                            (amount / parity) * (rewards / 100)
                          }
                          costPerUnit={
                            type === "boomerang"
                              ? (selectedProgramTarget.statistics.amountInStock *
                                  selectedProgramTarget.statistics.costPerUnit +
                                  (amount + amount * (rewards / 100)) *
                                    (((amount - amount * (rewardsBack / 100)) *
                                      selectedProgramOrigin.statistics.costPerUnit) /
                                      (amount + amount * (rewards / 100)))) /
                                (amount +
                                  amount * (rewards / 100) +
                                  selectedProgramTarget.statistics.amountInStock)
                              : ((selectedProgramTarget.statistics.amountInStock *
                                  selectedProgramTarget.statistics.costPerUnit +
                                  ((amount / parity + (amount / parity) * (rewards / 100)) *
                                    ((amount / parity) *
                                      selectedProgramOrigin.statistics.costPerUnit)) /
                                    (amount / parity + (amount / parity) * (rewards / 100))) *
                                  parity) /
                                (amount / parity +
                                  (amount / parity) * (rewards / 100) +
                                  selectedProgramTarget.statistics.amountInStock)
                          }
                          unitMeasure={selectedProgramTarget.config.unitMeasure}
                        />
                      )}
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
          <MDBox mt={4} mb={1} mr={1} ml={1} display="flex" justifyContent="space-between">
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => {
                onClose(false);
              }}
            >
              Voltar
            </MDButton>
            <MDButton variant="gradient" color="primary" onClick={save}>
              {saving ? (
                <MDBox display="flex" justifyContent="space-between" color="text">
                  <CircularProgress color="text" size={15} />
                  &nbsp;Salvando
                </MDBox>
              ) : (
                "Salvar"
              )}
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
  );
}

NewTransfer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewTransfer;
