import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Backdrop, Card, CircularProgress, Icon } from "@mui/material";

import MDBox from "components/CTFramework/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import ProgramCard from "components/Cards/ProgramCard";
import { AxiosContext } from "context";
import MDButton from "components/CTFramework/MDButton";

import NewProgram from "./components/NewProgram";

function Programs() {
  const { authAxios } = useContext(AxiosContext);

  const [isOpenModalProgram, setIsOpenModalProgram] = useState(false);
  const [loading, setLoading] = useState(true);
  const [program, setProgram] = useState({});
  const [programs, setPrograms] = useState([]);

  async function fetchData() {
    setLoading(true);
    const response = await authAxios.get("/rewards/v1/programs");

    setPrograms(response.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card id="create-program">
                <MDBox py={2} px={2} display="flex" justifyContent="right" alignItems="center">
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={() => {
                      setProgram({ dateStart: new Date() });
                      setIsOpenModalProgram(true);
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;Novo programa
                  </MDButton>
                </MDBox>
              </Card>
            </Grid>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {!loading && (
              <Grid item xs={12}>
                <MDBox p={2}>
                  <Grid container spacing={3}>
                    {programs.map((item) => (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={3}
                        key={item.id}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setProgram(item);
                          setIsOpenModalProgram(true);
                        }}
                      >
                        <ProgramCard
                          color="light"
                          program={item}
                          points={{
                            color: "success",
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </MDBox>
              </Grid>
            )}
          </Grid>
        </MDBox>
      </MDBox>
      <NewProgram
        program={program}
        setProgram={setProgram}
        isOpenModalProgram={isOpenModalProgram}
        onClose={(reload) => {
          if (reload) {
            fetchData();
          }
          setIsOpenModalProgram(false);
        }}
      />
    </DashboardLayout>
  );
}

export default Programs;
