import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/CTFramework/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { AxiosContext } from "context";
import Utils from "helpers/utils";
import { Backdrop, Card, CircularProgress, MenuItem } from "@mui/material";
import MixedChart from "components/Charts/MixedChart";
import MDSelect from "components/CTFramework/MDSelect";
import { addMonths, format, parseISO } from "date-fns";

function PriceQuotes() {
  const { authAxios } = useContext(AxiosContext);

  const [chartHotMilhas, setChartHotMilhas] = useState({});
  const [chartMaxMilhas, setChartMaxMilhas] = useState({});
  const [loading, setLoading] = useState(true);
  const [program, setProgram] = useState("Latam Pass");

  async function fetchChart() {
    setLoading(true);
    const response = await authAxios.get(
      `/rewards/v1/quotes?dateStart=${format(
        addMonths(new Date(), -3),
        "yyyy-MM-dd"
      )}&dateEnd=${format(new Date(), "yyyy-MM-dd")}`
    );
    const quotes = Utils.groupBy(response.data, "company");

    const chartConfigHotMilhas = {
      labels: [],
      datasets: [
        { label: "HotMilhas", data: [], color: "error", chartType: "default-line", fill: true },
        { label: "Media HotMilhas", data: [], color: "primary", chartType: "default-line" },
      ],
    };

    const chartConfigMaxMilhas = {
      labels: [],
      datasets: [
        { label: "MaxMilhas", data: [], color: "info", chartType: "default-line", fill: true },
        { label: "Media MaxMilhas", data: [], color: "secondary", chartType: "default-line" },
      ],
    };

    const avgHotMilhas =
      quotes[0].filter((q) => q.program === program).reduce((a, b) => a + Number(b.price), 0) /
      quotes[0].filter((q) => q.program === program).length;
    quotes[0]
      .filter((q) => q.program === program)
      .forEach((item) => {
        chartConfigHotMilhas.labels.push(format(parseISO(item.date), "dd/MM/yyyy"));
        chartConfigHotMilhas.datasets[0].data.push(item.price);
        chartConfigHotMilhas.datasets[1].data.push(avgHotMilhas);
      });

    const avgMaxMilhas =
      quotes[1].filter((q) => q.program === program).reduce((a, b) => a + Number(b.price), 0) /
      quotes[1].filter((q) => q.program === program).length;
    quotes[1]
      .filter((q) => q.program === program)
      .forEach((item) => {
        chartConfigMaxMilhas.labels.push(format(parseISO(item.date), "dd/MM/yyyy"));
        chartConfigMaxMilhas.datasets[0].data.push(item.price);
        chartConfigMaxMilhas.datasets[1].data.push(avgMaxMilhas);
      });

    setChartHotMilhas(chartConfigHotMilhas);
    setChartMaxMilhas(chartConfigMaxMilhas);

    setLoading(false);
  }

  useEffect(() => {
    fetchChart();
  }, [program]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <Card sx={{ marginBottom: "1em" }}>
                <MDBox display="flex" justifyContent="flex-end" p={2}>
                  <MDSelect
                    label="Programa"
                    sx={{ width: 200 }}
                    value={program}
                    onChange={(e) => setProgram(e.target.value)}
                  >
                    <MenuItem value="Latam Pass">Latam Pass</MenuItem>
                    <MenuItem value="Smiles">Smiles</MenuItem>
                    <MenuItem value="Tudo Azul">Tudo Azul</MenuItem>
                  </MDSelect>
                </MDBox>
              </Card>
              {!loading && (
                <>
                  <Card sx={{ marginBottom: 5 }}>
                    <MixedChart chart={chartHotMilhas} title="Cotação Histórica HotMilhas" />
                  </Card>
                  <Card>
                    <MixedChart chart={chartMaxMilhas} title="Cotação Histórica MaxMilhas" />
                  </Card>
                </>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default PriceQuotes;
