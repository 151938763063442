import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "context";

function PrivateRoute({ children }) {
  const { getUser } = useAuth();
  if (!getUser()) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
