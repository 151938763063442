import MDBox from "components/CTFramework/MDBox";
import MDTypography from "components/CTFramework/MDTypography";
import Formatters from "helpers/formatters";
import Utils from "helpers/utils";
import PropTypes from "prop-types";

function ProgramDetails({ amountInStock, costPerUnit, unitMeasure }) {
  return (
    <MDBox pb={1} pl={2} display="flex" justifyContent="space-between">
      <MDBox display="flex" justifyContent="right">
        <MDTypography component="p" variant="button" color="text" display="flex">
          <MDTypography component="span" variant="button" fontWeight="bold" color="text">
            {Formatters.number(amountInStock)}
          </MDTypography>
          &nbsp;pts
        </MDTypography>
      </MDBox>
      <MDBox px={2} display="flex" flexDirection="column" justifyContent="right">
        <MDTypography component="p" variant="button" color="text" display="flex">
          Preço medio&nbsp;
        </MDTypography>
        <MDTypography component="span" variant="button" fontWeight="bold" color="success">
          {Formatters.currency(Utils.getCostInUnit(costPerUnit, unitMeasure))}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

ProgramDetails.propTypes = {
  amountInStock: PropTypes.number.isRequired,
  costPerUnit: PropTypes.number.isRequired,
  unitMeasure: PropTypes.string.isRequired,
};

export default ProgramDetails;
