import PropTypes from "prop-types";

import MDBox from "components/CTFramework/MDBox";
import MDInput from "components/CTFramework/MDInput";
import {
  Avatar,
  Card,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import MDTypography from "components/CTFramework/MDTypography";
import MDButton from "components/CTFramework/MDButton";
import { useContext, useEffect, useState } from "react";
import { AxiosContext } from "context";
import MDSelect from "components/CTFramework/MDSelect";
import { DatePicker } from "@mui/x-date-pickers";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MaskedTextField from "components/CTFramework/MaskedTextField";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";

function NewProgram({ isOpenModalProgram, onClose, program, setProgram }) {
  const { authAxios } = useContext(AxiosContext);
  const confirm = useConfirm();

  const [amountInStock, setAmountInStock] = useState(0);
  const [cost, setCost] = useState(0);
  const [programsConfig, setProgramsConfig] = useState([]);
  const [selectedProgramConfig, setSelectedProgramConfig] = useState({ id: "" });
  const [showPassword, setShowPassword] = useState(false);

  const save = async () => {
    if (selectedProgramConfig.id !== "") {
      try {
        if (program.id) {
          await authAxios.patch(`/rewards/v1/programs/${program.id}`, program);
        } else {
          await authAxios.post("/rewards/v1/programs", {
            ...program,
            config: selectedProgramConfig,
            statistics: { amountInStock, costPerUnit: cost / amountInStock || 0, emissions: 0 },
          });
        }

        setAmountInStock(0);
        setCost(0);
        setSelectedProgramConfig({ id: "" });
        onClose(true);
      } catch (err) {
        toast.error(err.response.data.message);
      }
    } else {
      toast.error("Preencha os campos obrigatórios");
    }
  };

  const remove = async () => {
    try {
      if (program.id) {
        confirm({
          description: "Deseja realmente apagar esse programa e todas seus dados?",
        }).then(async () => {
          await authAxios.delete(`/rewards/v1/programs/${program.id}`);

          setAmountInStock(0);
          setCost(0);
          setSelectedProgramConfig({ id: "" });
          onClose(true);
        });
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await authAxios.get(`/admin/v1/programs/configs`);
      setProgramsConfig(response.data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (program.id) {
      setAmountInStock(program.statistics.amountInStock);
      setCost(program.statistics.costPerUnit * program.statistics.amountInStock);
      setSelectedProgramConfig(program.config);
    }
  }, [program]);

  return (
    <Modal open={isOpenModalProgram} onClose={() => onClose(false)}>
      <MDBox
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
        }}
      >
        <Card color="primary">
          <MDBox
            variant={selectedProgramConfig.id !== "" ? "contained" : "gradient"}
            bgColor={selectedProgramConfig.id !== "" ? selectedProgramConfig.color : "info"}
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Progama de pontos
            </MDTypography>

            {program.id && (
              <MDBox mt={1}>
                <Icon fontSize="default" sx={{ cursor: "pointer" }} onClick={remove}>
                  delete_forever
                </Icon>
              </MDBox>
            )}
          </MDBox>
          <MDBox display="flex" flexDirection="row">
            <MDBox display="flex" flexDirection="column" style={{ width: "50%" }}>
              <MDBox mb={2} mx={2} mt={1} display="flex" flexDirection="row">
                <MDSelect
                  value={selectedProgramConfig.id}
                  error={!selectedProgramConfig}
                  onChange={(e) =>
                    setSelectedProgramConfig(programsConfig.find((p) => p.id === e.target.value))
                  }
                  label="Programa"
                  fullWidth
                  disabled={!!program?.id}
                >
                  <MenuItem>Selecione</MenuItem>
                  {programsConfig.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </MDSelect>

                {selectedProgramConfig.icon && (
                  <MDBox ml={1}>
                    <Avatar
                      variant="rounded"
                      sizes="small"
                      src={`img/icons/programs/${selectedProgramConfig.icon}`}
                    />
                  </MDBox>
                )}
              </MDBox>
              <MDBox mb={2} mx={2}>
                <MDInput
                  type="text"
                  rows={2}
                  multiline
                  label="Descrição"
                  fullWidth
                  value={program.description}
                  onChange={(e) => setProgram({ ...program, description: e.target.value })}
                />
              </MDBox>

              <MDBox mb={2} mx={2}>
                <DatePicker
                  label="Data de inicio"
                  inputFormat="dd/MM/yyyy"
                  value={program.dateStart}
                  onChange={(newDate) => setProgram({ ...program, dateStart: newDate })}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </MDBox>
              {!program?.id && (
                <>
                  <MDBox mb={2} mx={2}>
                    <MDInput
                      type="number"
                      label="Saldo Inicial"
                      fullWidth
                      value={amountInStock}
                      error={amountInStock < 0}
                      onChange={(e) => setAmountInStock(Number(e.target.value))}
                      InputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2} mx={2}>
                    <MDInput
                      type="number"
                      label="Custo de aquisição"
                      fullWidth
                      value={cost}
                      error={cost < 0}
                      onChange={(e) => setCost(Number(e.target.value))}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                  </MDBox>
                </>
              )}
            </MDBox>

            <MDBox display="flex" flexDirection="column" style={{ width: "50%" }}>
              <MDBox mb={2} mx={2} mt={1}>
                <MDInput
                  name="email"
                  type="text"
                  label="E-mail"
                  fullWidth
                  value={program.email}
                  onChange={(e) => setProgram({ ...program, email: e.target.value })}
                />
              </MDBox>

              <MDBox mb={2} mx={2} mt={1}>
                <MaskedTextField
                  name="phone"
                  label="Telefone"
                  placeholder="(00) 00000-0000"
                  fullWidth
                  value={program.phone}
                  onAccept={(value) => {
                    setProgram({ ...program, phone: value });
                  }}
                  onChange={(e) => setProgram({ ...program, phone: e.target.value })}
                  mask="(00) 00000-0000"
                />
              </MDBox>

              <MDBox mb={2} mx={2} mt={1}>
                <MaskedTextField
                  name="document"
                  label="CPF"
                  placeholder="000.000.000-00"
                  fullWidth
                  value={program.document}
                  onAccept={(value) => {
                    setProgram({ ...program, document: value });
                  }}
                  onChange={(e) => {
                    setProgram({ ...program, document: e.target.value });
                  }}
                  mask="000.000.000-00"
                />
              </MDBox>

              <MDBox mb={2} mx={2} mt={1}>
                <MDInput
                  name="passwordProgram"
                  type={showPassword ? "text" : "password"}
                  label="Senha"
                  fullWidth
                  value={program.password}
                  onChange={(e) => setProgram({ ...program, password: e.target.value })}
                  InputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBox>
            </MDBox>
          </MDBox>

          <MDBox mt={4} mb={1} mr={1} ml={1} display="flex" justifyContent="space-between">
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => {
                setAmountInStock(0);
                setCost(0);
                setSelectedProgramConfig({ id: "" });
                onClose(false);
              }}
            >
              Voltar
            </MDButton>
            <MDButton variant="gradient" color="primary" onClick={save}>
              Salvar
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
  );
}

NewProgram.propTypes = {
  isOpenModalProgram: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  program: PropTypes.object.isRequired,
  setProgram: PropTypes.func.isRequired,
};

export default NewProgram;
