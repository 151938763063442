import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, Icon, Modal } from "@mui/material";
import { toast } from "react-toastify";

import MDBox from "components/CTFramework/MDBox";
import MDTypography from "components/CTFramework/MDTypography";
import MDButton from "components/CTFramework/MDButton";
import { AxiosContext } from "context";
import Formatters from "helpers/formatters";

function NotificationModal({ isOpen, onClose, notification }) {
  const { authAxios } = useContext(AxiosContext);

  const update = async (read) => {
    try {
      await authAxios.patch(`/user/v1/notifications/${notification.id}`, {
        read,
      });
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const remove = async () => {
    try {
      await authAxios.delete(`/user/v1/notifications/${notification.id}`);
      onClose(true);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    if (notification?.id) {
      update(true);
    }
  }, [notification]);

  return (
    <Modal open={isOpen} onClose={() => onClose(false)}>
      <MDBox
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: 300,
        }}
      >
        <Card color="primary">
          <MDBox m={2}>
            <MDTypography variant="button" fontWeight="medium" color="text">
              {notification.message || ""}
            </MDTypography>
          </MDBox>

          <MDBox m={2} mt={0}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Criado em: {Formatters.dateTime(notification.createdAt)}
            </MDTypography>
          </MDBox>

          <MDBox mt={4} mb={1} mr={1} ml={1} display="flex" justifyContent="space-between">
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => {
                remove();
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
            <MDButton
              variant="gradient"
              color="primary"
              onClick={() => {
                update(false);
                onClose(false);
                // eslint-disable-next-line no-param-reassign
                notification.read = false;
              }}
            >
              <Icon>mark_as_unread</Icon>
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
  );
}

NotificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired,
};

export default NotificationModal;
