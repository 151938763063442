import PropTypes from "prop-types";

import MDBox from "components/CTFramework/MDBox";
import MDInput from "components/CTFramework/MDInput";
import {
  Avatar,
  Card,
  CircularProgress,
  Grid,
  Icon,
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import MDTypography from "components/CTFramework/MDTypography";
import MDButton from "components/CTFramework/MDButton";
import { useContext, useEffect, useState } from "react";
import { AxiosContext } from "context";
import MDSelect from "components/CTFramework/MDSelect";
import { DatePicker } from "@mui/x-date-pickers";
import PaymentMethod from "components/PaymentMethod";
import { useIntl } from "react-intl";
import RecurrenceEnum from "enuns/recurrence";
import Formatters from "helpers/formatters";
import ProgramDetails from "components/ProgramDetails";
import { addMonths, differenceInMonths, isValid, parseISO } from "date-fns";
import StatusEnum from "enuns/status";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";

function NewSubscription({ isOpenModal, onClose, subscription, setSubscription }) {
  const { authAxios } = useContext(AxiosContext);
  const confirm = useConfirm();

  const { formatMessage } = useIntl();

  const [programs, setPrograms] = useState([]);
  const [points, setPoints] = useState(0);
  const [recurrence, setRecurrence] = useState("");
  const [rewardsDate, setRewardsDate] = useState(new Date());
  const [selectedProgram, setSelectedProgram] = useState({ id: "" });
  const [saving, setSaving] = useState(false);

  const addRewards = () => {
    if (points > 0) {
      const rewards = [...subscription.rewards];
      if (recurrence !== "") {
        let dateProcess = subscription.dateStart;
        while (dateProcess < subscription.dateEnd) {
          rewards.push({ id: Math.random(), points, date: dateProcess });
          switch (recurrence) {
            case RecurrenceEnum.Monthly:
              dateProcess = addMonths(dateProcess, 1);
              break;
            case RecurrenceEnum.Quarterly:
              dateProcess = addMonths(dateProcess, 3);
              break;
            case RecurrenceEnum.Semiannual:
              dateProcess = addMonths(dateProcess, 6);
              break;
            default:
              dateProcess = addMonths(dateProcess, 12);
              break;
          }
        }
      } else {
        rewards.push({ id: Math.random(), points, date: rewardsDate });
      }

      setSubscription({
        ...subscription,
        rewards,
      });

      setPoints(0);
      setRecurrence("");
      setRewardsDate(new Date());
    }
  };

  const remove = async () => {
    try {
      if (subscription.id) {
        confirm({
          description: "Deseja realmente apagar esse assinatura e todos as transações geradas?",
        }).then(async () => {
          await authAxios.delete(`/rewards/v1/subscription/${subscription.id}`);
          onClose(true);
        });
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const save = async () => {
    if (
      subscription.recurrence !== "" &&
      subscription.programId !== "" &&
      subscription.payment.method !== ""
    ) {
      try {
        setSaving(true);
        if (subscription.id) {
          await authAxios.put(`/rewards/v1/subscription/${subscription.id}`, subscription);
        } else {
          await authAxios.post("/rewards/v1/subscription", subscription);
        }

        setSelectedProgram({ id: "" });
        onClose(true);
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setSaving(false);
      }
    } else {
      toast.error("Preencha os campos obrigatórios");
    }
  };

  const cancelSubscription = async () => {
    confirm({
      description: "Deseja realmente cancelar essa assinatura?",
    }).then(async () => {
      try {
        await authAxios.put(`/rewards/v1/subscription/${subscription.id}`, {
          ...subscription,
          status: StatusEnum.Canceled,
        });

        setSelectedProgram({ id: "" });
        onClose(true);
      } catch (err) {
        toast.error(err.response.data.message);
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await authAxios.get(`/rewards/v1/programs`);
      setPrograms(response.data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    let dateEnd;
    let processDate = subscription.dateStart;
    if (!isValid(processDate)) {
      processDate = parseISO(subscription.dateStart);
    }
    switch (subscription.recurrence) {
      case RecurrenceEnum.Quarterly:
        dateEnd = addMonths(processDate, 3);
        break;
      case RecurrenceEnum.Semiannual:
        dateEnd = addMonths(processDate, 6);
        break;
      default:
        dateEnd = addMonths(processDate, 12);
        break;
    }

    setSubscription({
      ...subscription,
      dateEnd,
    });
  }, [subscription.recurrence, subscription.dateStart]);

  useEffect(() => {
    if (subscription?.program?.id) {
      setSelectedProgram(programs.find((p) => p.id === subscription?.program?.id) || { id: "" });
    }
  }, [subscription.program]);

  return (
    <Modal open={isOpenModal} onClose={() => onClose(false)}>
      <MDBox
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: !subscription.id || subscription?.rewards.length > 0 ? 1200 : 670,
        }}
      >
        <Card color="primary">
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Assinatura
            </MDTypography>

            <MDBox mt={1}>
              <Icon fontSize="default" sx={{ cursor: "pointer" }} onClick={remove}>
                delete_forever
              </Icon>
            </MDBox>
          </MDBox>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              lg={!subscription.id || subscription?.rewards.length > 0 ? 4 : 6}
            >
              <MDBox display="flex" flexDirection="column">
                <MDBox mb={2} mx={2} mt={1} display="flex" flexDirection="row">
                  <MDSelect
                    value={subscription.program.id}
                    error={subscription.program.id === ""}
                    onChange={(e) => {
                      const program = programs.find((p) => p.id === e.target.value) || { id: "" };
                      let recurrenceSelected;
                      if (program.config.id === "a3a1e8fc-2a44-41fb-aea7-6ac02ff8f224") {
                        recurrenceSelected = "weekly";
                      }
                      setSubscription({
                        ...subscription,
                        recurrence: recurrenceSelected,
                        program,
                      });
                      setSelectedProgram(program);
                    }}
                    label="Programa"
                    style={{ minWidth: selectedProgram?.config?.icon ? 250 : 309 }}
                    disabled={!!subscription.id}
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {programs.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.config.name} - {item.description}
                      </MenuItem>
                    ))}
                  </MDSelect>

                  {selectedProgram?.config?.icon && (
                    <MDBox ml={1}>
                      <Avatar
                        variant="rounded"
                        sizes="small"
                        src={`img/icons/programs/${selectedProgram.config.icon}`}
                      />
                    </MDBox>
                  )}
                </MDBox>

                <MDBox mb={2} mx={2} mt={1}>
                  <MDSelect
                    value={subscription.recurrence}
                    error={subscription.recurrence === ""}
                    onChange={(e) =>
                      setSubscription({ ...subscription, recurrence: e.target.value })
                    }
                    label="Recorrência do pagamento"
                    style={{ minWidth: 309, width: "100%" }}
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {Object.keys(RecurrenceEnum).map((key) => (
                      <MenuItem value={RecurrenceEnum[key]} key={key}>
                        {formatMessage({ id: RecurrenceEnum[key] })}
                      </MenuItem>
                    ))}
                  </MDSelect>
                </MDBox>

                <MDBox display="flex" flexDirection="row">
                  <MDBox mb={2} ml={2}>
                    <DatePicker
                      label="Data de início"
                      inputFormat="dd/MM/yyyy"
                      value={subscription.dateStart}
                      onChange={(newDate) =>
                        setSubscription({
                          ...subscription,
                          payment: { ...subscription.payment, dateStart: newDate },
                          dateStart: newDate,
                        })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </MDBox>

                  <MDBox mb={2} ml={1}>
                    <DatePicker
                      label="Data de fim"
                      inputFormat="dd/MM/yyyy"
                      value={subscription.dateEnd}
                      onChange={(newDate) =>
                        setSubscription({
                          ...subscription,
                          dateEnd: newDate,
                        })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </MDBox>
                </MDBox>

                <MDBox display="flex" flexDirection="row">
                  <MDBox mb={2} ml={2}>
                    <MDInput
                      type="number"
                      label="Valor"
                      value={subscription.value}
                      error={subscription.value < 0}
                      onChange={(e) =>
                        setSubscription({
                          ...subscription,
                          value: Number(e.target.value),
                          payment: { ...subscription.payment, value: Number(e.target.value) },
                        })
                      }
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      style={{ width: 150.5 }}
                    />
                  </MDBox>

                  <MDBox mb={2} ml={1}>
                    <MDInput
                      type="number"
                      label="Pontos por Recorrência"
                      fullWidth
                      value={subscription.points}
                      error={subscription.points < 0}
                      onChange={(e) =>
                        setSubscription({ ...subscription, points: Number(e.target.value) })
                      }
                      InputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      style={{ width: 150.5 }}
                    />
                  </MDBox>
                </MDBox>

                {(selectedProgram.config?.unitMeasure ||
                  subscription?.program?.config?.unitMeasure) && (
                  <MDBox mb={2} ml={2} shadow coloredShadow="light" borderRadius="lg">
                    <MDTypography
                      component="span"
                      variant="button"
                      fontWeight="bold"
                      color="text"
                      style={{ marginLeft: 15 }}
                    >
                      Pontos adquiridos
                    </MDTypography>
                    <ProgramDetails
                      amountInStock={
                        subscription.points *
                          differenceInMonths(subscription.dateEnd, subscription.dateStart) +
                        subscription.rewards.reduce((a, c) => a + c.points, 0)
                      }
                      costPerUnit={
                        (subscription.recurrence === RecurrenceEnum.Monthly
                          ? subscription.value *
                            differenceInMonths(subscription.dateEnd, subscription.dateStart)
                          : subscription.value) /
                        (subscription.points *
                          differenceInMonths(subscription.dateEnd, subscription.dateStart) +
                          subscription.rewards.reduce((a, c) => a + c.points, 0))
                      }
                      unitMeasure={
                        selectedProgram?.config?.unitMeasure ||
                        subscription?.program?.config?.unitMeasure
                      }
                    />
                  </MDBox>
                )}
              </MDBox>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={!subscription.id || subscription?.rewards.length > 0 ? 4 : 6}
            >
              <MDBox display="flex" flexDirection="column" mt={1}>
                <PaymentMethod
                  cost={subscription.value}
                  installments={subscription.payment.installments}
                  setInstallments={(newValue) =>
                    setSubscription({
                      ...subscription,
                      payment: {
                        ...subscription.payment,
                        installments: newValue,
                        value: subscription.value / newValue,
                      },
                    })
                  }
                  paymentDate={subscription.payment.dateStart}
                  setPaymentDate={(newDate) =>
                    setSubscription({
                      ...subscription,
                      payment: { ...subscription.payment, dateStart: newDate },
                    })
                  }
                  paymentMethod={subscription.payment.method}
                  setPaymentMethod={(newValue) =>
                    setSubscription({
                      ...subscription,
                      payment: { ...subscription.payment, method: newValue },
                    })
                  }
                />
              </MDBox>
            </Grid>

            {(!subscription.id || subscription?.rewards.length > 0) && (
              <Grid item xs={12} md={6} lg={4}>
                <MDBox display="flex" flexDirection="column">
                  {(!subscription.id || subscription?.rewards.length > 0) && (
                    <MDBox
                      mx={2}
                      pt={2}
                      display="flex"
                      flexDirection="column"
                      borderRadius="lg"
                      coloredShadow="light"
                    >
                      {!subscription.id && (
                        <MDBox mx={2} display="flex" flexDirection="column" justifyContent="center">
                          <MDBox display="flex" flexDirection="row" justifyContent="space-between">
                            <MDInput
                              style={{ width: 100, marginRight: 8 }}
                              type="number"
                              label="Bônus de"
                              value={points}
                              error={points < 0}
                              onChange={(e) => setPoints(Number(e.target.value))}
                              InputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                            />

                            <MDSelect
                              value={recurrence}
                              onChange={(e) => setRecurrence(e.target.value)}
                              label="Reccorrente"
                              style={{ width: 150 }}
                            >
                              <MenuItem value="">Selecione</MenuItem>
                              {Object.keys(RecurrenceEnum).map((key) => (
                                <MenuItem value={RecurrenceEnum[key]} key={key}>
                                  {formatMessage({ id: RecurrenceEnum[key] })}
                                </MenuItem>
                              ))}
                            </MDSelect>
                          </MDBox>
                          <MDBox
                            my={2}
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <DatePicker
                              label="ou em uma data"
                              inputFormat="dd/MM/yyyy"
                              value={rewardsDate}
                              onChange={setRewardsDate}
                              renderInput={(params) => (
                                <TextField {...params} style={{ width: 150 }} />
                              )}
                            />

                            <MDButton
                              iconOnly
                              variant="gradient"
                              color="success"
                              onClick={() => addRewards()}
                              style={{ marginTop: 1 }}
                            >
                              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      )}
                      <MDBox style={{ maxHeight: 250, overflowX: "auto" }}>
                        {subscription.rewards.map((item) => (
                          <MDBox
                            mb={2}
                            mx={2}
                            display="flex"
                            flexDirection="row"
                            borderRadius="lg"
                            coloredShadow="light"
                            bgColor="light"
                            justifyContent="space-between"
                            key={item.id}
                          >
                            <MDTypography variant="body2" style={{ margin: 5 }}>
                              Bônus de {item.points} em {Formatters.dateLong(item.date)}
                            </MDTypography>

                            {!subscription.id && (
                              <MDButton
                                iconOnly
                                variant="text"
                                color="error"
                                style={{ marginTop: -5 }}
                                onClick={() => {
                                  setSubscription({
                                    ...subscription,
                                    rewards: [
                                      ...subscription.rewards.filter((i) => i.id !== item.id),
                                    ],
                                  });
                                }}
                              >
                                <Icon sx={{ fontWeight: "bold" }}>remove</Icon>
                              </MDButton>
                            )}
                          </MDBox>
                        ))}
                      </MDBox>
                    </MDBox>
                  )}
                </MDBox>
              </Grid>
            )}
          </Grid>

          <MDBox mt={4} mb={1} mr={1} ml={1} display="flex" justifyContent="space-between">
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => {
                setSelectedProgram({ id: "" });
                onClose(false);
              }}
            >
              Voltar
            </MDButton>

            {subscription.id && (
              <MDButton variant="gradient" color="warning" onClick={cancelSubscription}>
                Cancelar Assinatura
              </MDButton>
            )}

            <MDButton variant="gradient" color="primary" onClick={save}>
              {saving ? (
                <MDBox display="flex" justifyContent="space-between" color="text">
                  <CircularProgress color="text" size={15} />
                  &nbsp;Salvando
                </MDBox>
              ) : (
                "Salvar"
              )}
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
  );
}

NewSubscription.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired,
  setSubscription: PropTypes.func.isRequired,
};

export default NewSubscription;
