import { Backdrop, Card, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";
import MixedChart from "components/Charts/MixedChart";
import MDBox from "components/CTFramework/MDBox";
import MDTypography from "components/CTFramework/MDTypography";
import DataTable from "components/DataTable";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { AxiosContext } from "context";
import { format, parseISO } from "date-fns";
import Formatters from "helpers/formatters";
import Utils from "helpers/utils";
import { useContext, useEffect, useState } from "react";

const columns = [
  { Header: "Programa", accessor: "program", width: "10%", align: "left" },
  { Header: "Descrição", accessor: "description", width: "20%", align: "left" },
  { Header: "Data", accessor: "date", align: "left" },
  { Header: "Pontos", accessor: "points", align: "center" },
  { Header: "Valor Compra", accessor: "valueCost", align: "right" },
  { Header: "CPM Compra", accessor: "cpmCost", align: "right" },
  { Header: "Valor Venda", accessor: "valueSell", align: "right" },
  { Header: "CPM Venda", accessor: "cpmSell", align: "right" },
  { Header: "Lucro", accessor: "profit", align: "right" },
  { Header: "%", accessor: "profitPercent", align: "right" },
];

function SalesReport() {
  const { authAxios } = useContext(AxiosContext);

  const [chart, setChart] = useState({});
  const [rows, setRows] = useState([]);
  const [costForecast, setCostForecast] = useState(0);
  const [costTotal, setCostTotal] = useState(0);
  const [netForecast, setNetForecast] = useState(0);
  const [netTotal, setNetTotal] = useState(0);

  const [loading, setLoading] = useState(true);

  const parseRows = (items) => {
    setRows(
      items.map((item) => ({
        program: (
          <MDTypography variant="button" fontWeight="regular">
            {`${item.program.config.name} ${item.program.description}`}
          </MDTypography>
        ),
        description: (
          <MDTypography variant="button" fontWeight="regular">
            {item.description}
          </MDTypography>
        ),
        date: (
          <MDTypography variant="caption" fontWeight="regular">
            {Formatters.dateShort(parseISO(item.date))}
          </MDTypography>
        ),
        points: (
          <MDTypography variant="button" fontWeight="medium">
            {item.amount}
          </MDTypography>
        ),
        valueCost: (
          <MDTypography variant="button" fontWeight="medium">
            {Formatters.currency(item.amount * item.costPerUnit)}
          </MDTypography>
        ),
        cpmCost: (
          <MDTypography variant="button" fontWeight="medium">
            {Formatters.currency(
              Utils.getCostInUnit(item.costPerUnit, item.program.config.unitMeasure)
            )}
          </MDTypography>
        ),
        valueSell: (
          <MDTypography variant="button" fontWeight="medium">
            {Formatters.currency(item.amount * item.sellPerUnit)}
          </MDTypography>
        ),
        cpmSell: (
          <MDTypography variant="button" fontWeight="medium">
            {Formatters.currency(
              Utils.getCostInUnit(item.sellPerUnit, item.program.config.unitMeasure)
            )}
          </MDTypography>
        ),
        profit: (
          <MDTypography
            variant="button"
            fontWeight="medium"
            color={
              item.amount * item.sellPerUnit - item.amount * item.costPerUnit > 0
                ? "success"
                : "error"
            }
          >
            {Formatters.currency(item.amount * item.sellPerUnit - item.amount * item.costPerUnit)}
          </MDTypography>
        ),
        profitPercent: (
          <MDTypography
            variant="button"
            fontWeight="medium"
            color={
              item.amount * item.sellPerUnit - item.amount * item.costPerUnit > 0
                ? "success"
                : "error"
            }
          >
            {(
              (item.amount * item.sellPerUnit * 100) / (item.amount * item.costPerUnit) -
              100
            ).toFixed(2)}
            %
          </MDTypography>
        ),
      }))
    );
  };

  async function fetchChart(response) {
    setLoading(true);

    const chartConfig = {
      labels: [],
      datasets: [{ label: "Resultado", data: [], color: "info" }],
    };

    const sales = [];

    let cost = 0;
    let total = 0;
    response.data
      .filter((item) => !item.transfer)
      .sort((a, b) => parseISO(a.date) - parseISO(b.date))
      .forEach((transaction) => {
        const monthIncoming = sales.find(
          (item) => item.month === format(parseISO(transaction.date), "MM/yyyy")
        );
        if (monthIncoming) {
          monthIncoming.value +=
            transaction.amount * (transaction.sellPerUnit - transaction.costPerUnit);
        } else {
          sales.push({
            month: format(parseISO(transaction.date), "MM/yyyy"),
            value: transaction.amount * (transaction.sellPerUnit - transaction.costPerUnit),
          });
          cost += transaction.amount * transaction.costPerUnit;
          total += transaction.amount * transaction.sellPerUnit;
        }
      });

    setCostTotal(cost);
    setNetTotal(total);
    sales.forEach((item) => {
      chartConfig.labels.push(item.month);
      chartConfig.datasets[0].data.push(item.value);
    });

    setChart(chartConfig);

    setLoading(false);
  }

  async function fetchTransactions() {
    setLoading(true);

    const response = await authAxios.get(`/rewards/v1/transactions?type=outcoming`);

    await fetchChart(response);
    parseRows(response.data.filter((item) => !item.transfer));

    setLoading(false);
  }

  async function fetchForecast() {
    const response = await authAxios.get(`/rewards/v1/forecast`);
    setCostForecast(
      response.data
        .filter((item) => item.sellInAvarageLastSix)
        .reduce((a, c) => a + c.costPerUnit * c.amountInStock, 0)
    );
    setNetForecast(
      response.data
        .filter((item) => item.sellInAvarageLastSix)
        .reduce((a, c) => a + c.sellInAvarageLastSix, 0)
    );
  }

  useEffect(() => {
    fetchForecast();
    fetchTransactions();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && (
        <MDBox mt={8}>
          <MDBox mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Card>
                  <MixedChart chart={chart} title="Resultado Mensal" />
                </Card>
              </Grid>
              <Grid item xs={3}>
                <DefaultInfoCard
                  title="Resultado realizado"
                  value={Formatters.currency(netTotal - costTotal)}
                  valueDetail={` / ${((netTotal * 100) / costTotal - 100).toFixed(2)}%`}
                  color="info"
                />
                <MDBox mt={1.5}>
                  <DefaultInfoCard
                    title="Resultado Experado"
                    description="Média últimas 6 vendas"
                    value={Formatters.currency(netForecast - costForecast)}
                    valueDetail={` / ${((netForecast * 100) / costForecast - 100).toFixed(2)}%`}
                    color="info"
                  />
                </MDBox>
                <MDBox mt={1.5}>
                  <DefaultInfoCard
                    title="Resultado Final"
                    description="Realizado + Experado"
                    value={Formatters.currency(netTotal - costTotal + netForecast - costForecast)}
                    valueDetail={` / ${(
                      ((netTotal + netForecast) * 100) / (costTotal + costForecast) -
                      100
                    ).toFixed(2)}%`}
                    color="info"
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} mt={2}>
                <Card>
                  <MDTypography variant="h6" mt={2} ml={2}>
                    Vendas Realizadas
                  </MDTypography>
                  <DataTable
                    table={{ columns, rows }}
                    noEndBorder
                    entriesPerPage={false}
                    isSorted={false}
                  />
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default SalesReport;
