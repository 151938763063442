import { useContext, useEffect, useState } from "react";
import MDBox from "components/CTFramework/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import NewEmission from "components/NewEmission";
import MDButton from "components/CTFramework/MDButton";
import { toast } from "react-toastify";
import MDTypography from "components/CTFramework/MDTypography";
import { AxiosContext } from "context";
import MDSelect from "components/CTFramework/MDSelect";
import Formatters from "helpers/formatters";
import Icon from "@mui/material/Icon";
import { Card, Grid, IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { addMonths, endOfMonth, format, parseISO, startOfMonth } from "date-fns";
import MDInput from "components/CTFramework/MDInput";
import MaskedTextField from "components/CTFramework/MaskedTextField";
import { useConfirm } from "material-ui-confirm";
import { useLocation } from "react-router-dom";
import List from "./components/List";

function Emissions() {
  const [isOpenNewEmission, setIsOpenNewEmission] = useState(false);

  const { authAxios } = useContext(AxiosContext);
  const confirm = useConfirm();
  const location = useLocation();

  const [clients, setClients] = useState([]);
  const [document, setDocument] = useState("");
  const [locator, setLocator] = useState("");
  const [programs, setPrograms] = useState([]);
  const [selectedClient, setSelectedClient] = useState({ id: "" });
  const [selectedProgram, setSelectedProgram] = useState({ id: "" });
  const [rows, setRows] = useState([]);
  const [dateStart, setDateStart] = useState(addMonths(new Date(), -12));
  const [dateEnd, setDateEnd] = useState(endOfMonth(new Date()));

  const fetchClients = () => {
    authAxios.get("/crm/v1/clients").then((response) => {
      setClients(response.data);
    });
  };

  const fetchTickets = async () => {
    const response = await authAxios.get(
      `/rewards/v1/ticket?programId=${selectedProgram.id}&clientId=${
        selectedClient.id
      }&dateStart=${format(dateStart, "yyyy-MM-dd")}&dateEnd=${format(
        dateEnd,
        "yyyy-MM-dd"
      )}&locator=${locator}&document=${document} `
    );

    parseRows(response.data);
  };

  const remove = async (id) => {
    try {
      confirm({
        description: "Deseja realmente apagar essa passagem emitida?",
      }).then(async () => {
        await authAxios.delete(`/rewards/v1/ticket/${id}`);

        fetchTickets();
      });
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const parseRows = (items) => {
    setRows(
      items.map((item) => ({
        program: (
          <MDTypography variant="button" fontWeight="regular" sx={{ width: 150, display: "flex" }}>
            {item.program?.config.name} - {item.program?.description}
          </MDTypography>
        ),
        client: (
          <MDTypography variant="button" fontWeight="regular" sx={{ width: 200, display: "flex" }}>
            {item.client?.name}
          </MDTypography>
        ),
        passengers: item.passengers.map((passenger) => (
          <MDTypography
            key={passenger.id}
            variant="button"
            fontWeight="regular"
            sx={{ width: 200, display: "flex" }}
          >
            {passenger.document} - {passenger.name}
          </MDTypography>
        )),
        date: (
          <MDTypography variant="caption" fontWeight="regular">
            {Formatters.dateShort(parseISO(item.dateIssued))}
          </MDTypography>
        ),
        locator: (
          <MDTypography variant="button" fontWeight="medium">
            {item.locator}
          </MDTypography>
        ),
        action: (
          <MDBox display="flex" flexDirection="row">
            <Tooltip title="Remover transação">
              <IconButton onClick={() => remove(item.id)}>
                <Icon color="error">delete</Icon>
              </IconButton>
            </Tooltip>
          </MDBox>
        ),
      }))
    );
  };

  useEffect(() => {
    if (selectedProgram?.id) {
      fetchTickets();
    } else if (!selectedProgram?.id) {
      setRows([]);
    }
  }, [selectedProgram]);

  useEffect(() => {
    async function fetchData() {
      authAxios.get("/rewards/v1/programs").then((response) => {
        setPrograms(response.data);
        if (location?.state?.programId) {
          setSelectedProgram(response.data.find((p) => p.id === location?.state?.programId));
        }
      });
    }

    fetchClients();
    fetchData();
  }, [location]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Card sx={{ height: "100%" }}>
            <MDBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="5rem"
              height="3rem"
              mt={-3}
            >
              <MDTypography variant="caption" fontWeight="regular">
                Filtros
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" py={3} px={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                  <MDSelect
                    value={selectedProgram?.id || ""}
                    onChange={(e) => {
                      setSelectedProgram(programs.find((p) => p.id === e.target.value));
                    }}
                    label="Programa"
                    style={{ width: 250 }}
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {programs.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.config.name} - {item.description}
                      </MenuItem>
                    ))}
                  </MDSelect>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <MDSelect
                    value={selectedClient?.id || ""}
                    onChange={(e) => {
                      setSelectedClient(clients.find((p) => p.id === e.target.value));
                    }}
                    label="Cliente"
                    style={{ width: 250 }}
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {clients.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name} {item.document && ` - ${item.document}`}
                      </MenuItem>
                    ))}
                  </MDSelect>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <MaskedTextField
                    name="document"
                    label="Documento"
                    style={{ minWidth: 250 }}
                    value={document}
                    mask={document.length > 9 ? "000.000.000-00" : null}
                    onAccept={(value) => {
                      setDocument(value);
                    }}
                    onChange={(e) => {
                      setDocument(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </MDBox>

            <MDBox display="flex" justifyContent="space-between" alignItems="center" py={3} px={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                  <MDInput
                    type="text"
                    label="Localizador"
                    value={locator}
                    onChange={(e) => {
                      setLocator(e.target.value);
                    }}
                    style={{ minWidth: 250 }}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={5}>
                  <MDBox display="flex" alignItems="flex-start">
                    <DatePicker
                      label=""
                      value={dateStart}
                      onChange={setDateStart}
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} />}
                      maxDate={dateEnd}
                    />
                    <MDBox color="text" mx={1} mt={1.5} lineHeight={0}>
                      <MDTypography variant="button" fontWeight="medium">
                        até
                      </MDTypography>
                    </MDBox>
                    <DatePicker
                      label=""
                      value={dateEnd}
                      onChange={setDateEnd}
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} />}
                      minDate={dateStart}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox py={3} px={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={12}>
                  <MDBox display="flex" justifyContent="flex-end">
                    <MDButton
                      color="error"
                      onClick={() => {
                        setDateEnd(endOfMonth(new Date()));
                        setDateStart(startOfMonth(new Date()));
                        setDocument("");
                        setLocator("");
                        setSelectedClient({ id: "" });
                        setSelectedProgram({ id: "" });
                        setRows([]);
                      }}
                    >
                      <Icon>clear</Icon>&nbsp;Limpar
                    </MDButton>
                    <MDButton
                      color="info"
                      onClick={() => {
                        fetchTickets();
                      }}
                      style={{ marginLeft: "1em" }}
                    >
                      <Icon>search</Icon>&nbsp;Pesquisar
                    </MDButton>

                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={() => {
                        setIsOpenNewEmission(true);
                      }}
                      style={{ marginLeft: "1em" }}
                    >
                      &nbsp;Nova Emissão
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>

        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <List rows={rows} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>

      <NewEmission
        isOpen={isOpenNewEmission}
        onClose={(reload) => {
          if (reload) {
            fetchClients();
            fetchTickets();
          }
          setIsOpenNewEmission(false);
        }}
      />
    </DashboardLayout>
  );
}

export default Emissions;
