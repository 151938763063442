import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import MDBox from "components/CTFramework/MDBox";
import MDTypography from "components/CTFramework/MDTypography";
import { Avatar, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import Formatters from "../../../helpers/formatters";
import Utils from "../../../helpers/utils";

function ProgramCard({ color, points, program }) {
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Avatar
            variant="rounded"
            sizes="small"
            src={`img/icons/programs/${program.config.icon}`}
          />
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography variant="h5" fontWeight="light" color="text">
            {program.config.name}
          </MDTypography>
          <MDTypography variant="h6" fontWeight="light" color="text">
            &nbsp;{program.description}
          </MDTypography>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox pb={1} px={2} display="flex" justifyContent="right">
        <MDTypography component="p" variant="button" color="text" display="flex">
          <MDTypography component="span" variant="button" fontWeight="bold" color="text">
            {Formatters.number(program.statistics.amountInStock)}
          </MDTypography>
          &nbsp;pts
        </MDTypography>
      </MDBox>
      <MDBox px={2} display="flex" justifyContent="right">
        <MDTypography component="p" variant="button" color="text" display="flex">
          Preço medio&nbsp;
        </MDTypography>
      </MDBox>
      <MDBox pb={1} px={2} display="flex" justifyContent="right">
        <MDTypography component="span" variant="button" fontWeight="bold" color={points.color}>
          {Formatters.currency(
            Utils.getCostInUnit(program.statistics.costPerUnit, program.config.unitMeasure)
          )}
        </MDTypography>
      </MDBox>
      <MDBox px={2} display="flex" justifyContent="right">
        <MDTypography component="p" variant="button" color="text" display="flex">
          Custo aquisição
        </MDTypography>
      </MDBox>
      <MDBox pb={1} px={2} display="flex" justifyContent="right">
        <MDTypography component="span" variant="button" fontWeight="bold" color={points.color}>
          {Formatters.currency(program.statistics.costPerUnit * program.statistics.amountInStock)}
        </MDTypography>
      </MDBox>
      <Tooltip title="Ver emissões" placement="top">
        <Link to={{ pathname: `/emissoes` }} state={{ programId: program.id }}>
          <MDBox px={2} pb={1} display="flex" justifyContent="right">
            <MDTypography component="span" variant="button" fontWeight="bold" color="warning">
              {program.statistics.emissions}
            </MDTypography>
            <MDTypography component="p" variant="button" color="text" display="flex">
              &nbsp;CPFs utilizados
            </MDTypography>
          </MDBox>
        </Link>
      </Tooltip>
    </Card>
  );
}

// Setting default values for the props of ProgramCard
ProgramCard.defaultProps = {
  color: "info",
  points: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ProgramCard
ProgramCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  points: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
  }),
  program: PropTypes.object.isRequired,
};

export default ProgramCard;
