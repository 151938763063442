import UnitMesuare from "enuns/unitMeasure";

export default class Utils {
  static getCostInUnit(cost, unitMeasure) {
    if (unitMeasure === UnitMesuare.Mileage) {
      return cost * 1000;
    }

    return cost;
  }

  static getCPMInUnit(cpm, unitMeasure) {
    if (unitMeasure === UnitMesuare.Mileage) {
      return cpm / 1000;
    }

    return cpm;
  }

  static groupBy(arr, prop) {
    const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
    arr.forEach((obj) => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
  }
}
