import { useContext } from "react";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { AxiosContext, useThemeController, setDarkMode } from "context";

import MDBox from "components/CTFramework/MDBox";
import MDTypography from "components/CTFramework/MDTypography";

function PlatformSettings() {
  const { authAxios } = useContext(AxiosContext);

  const [controller, dispatch] = useThemeController();
  const { darkMode } = controller;

  const handleDarkMode = async () => {
    setDarkMode(dispatch, !darkMode);

    await authAxios.put("/user/v1/settings", {
      theme: !darkMode ? "dark" : "light",
    });

    const appConfig = localStorage.getItem("app_config");
    if (appConfig) {
      const data = JSON.parse(appConfig);
      data.user.configs.theme = !darkMode ? "dark" : "light";
      localStorage.setItem("app_config", JSON.stringify(data));
    }
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Configurações do sistema
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch checked={darkMode} onChange={handleDarkMode} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Tema escuro
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
