import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { ArrowBackRounded } from "@mui/icons-material";
import { AxiosContext } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MDBox from "components/CTFramework/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDInput from "components/CTFramework/MDInput";
import { Backdrop, Card, CircularProgress } from "@mui/material";
import MDButton from "components/CTFramework/MDButton";
import MDTypography from "components/CTFramework/MDTypography";
import StatusIssueEnum from "enuns/statusIssue";
import { useIntl } from "react-intl";
import Formatters from "helpers/formatters";
import { parseISO } from "date-fns";
import NewInteraction from "./components/NewInteraction";

function Issue() {
  const { authAxios } = useContext(AxiosContext);
  const location = useLocation();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [closing, setClosing] = useState(false);
  const [issue, setIssue] = useState({});
  const [isNew, setIsNew] = useState(true);
  const [isOpenNewInteraction, setIsOpenNewInteraction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postingInteraction, setPostInteraction] = useState(false);
  const [saving, setSaving] = useState(false);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");

  const fetchIssue = (id) => {
    setLoading(true);
    authAxios
      .get(`/support/v1/issues/${id}`)
      .then((response) => {
        setIssue(response.data);
      })
      .finally(() => setLoading(false));
  };

  const save = async () => {
    if (title !== "" && text !== "") {
      try {
        setSaving(true);

        const { data } = await authAxios.post("/support/v1/issues", {
          id: issue.id,
          attachaments: [],
          interactions: [{ text }],
          status: StatusIssueEnum.New,
          title,
        });
        fetchIssue(data.id);
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setSaving(false);
        setIsNew(false);
      }
    } else {
      toast.error("Preencha os campos obrigatórios!");
    }
  };

  const close = async () => {
    try {
      setClosing(true);

      await authAxios.put("/support/v1/issues", {
        id: issue.id,
        status: StatusIssueEnum.Closed,
      });
      fetchIssue(issue.id);
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setClosing(false);
    }
  };

  const postInteraction = async (message) => {
    if (message !== "") {
      try {
        setPostInteraction(true);

        await authAxios.post("/support/v1/issues/interactions", {
          id: issue.id,
          interactions: [{ text: message }],
          status: StatusIssueEnum.WaitResponse,
        });
        fetchIssue(issue.id);
        setIsOpenNewInteraction(false);
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setPostInteraction(false);
      }
    } else {
      toast.error("Preencha os campos obrigatórios!");
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      setIsNew(false);
      fetchIssue(location?.state?.id);
    }
  }, [location]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {!loading && (
        <MDBox>
          <Card>
            {isNew && (
              <MDBox>
                <MDBox mb={2} mx={2} mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} xl={12}>
                      <MDInput
                        name="title"
                        type="text"
                        label="Titulo"
                        fullWidth
                        value={issue.title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mb={2} mx={2} mt={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} xl={12}>
                      <MDInput
                        name="descriptioon"
                        type="text"
                        label="Descrição"
                        fullWidth
                        value={issue.description}
                        onChange={(e) => setText(e.target.value)}
                        rows={5}
                        multiline
                        required
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            )}
            {!isNew && (
              <MDBox>
                <MDBox mb={2} mx={2} mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDTypography variant="subtitle1" fontWeight="regular">
                        Titulo:
                      </MDTypography>
                      <MDTypography variant="caption" fontWeight="regular">
                        {issue.title}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDTypography variant="subtitle1" fontWeight="regular">
                        Status:
                      </MDTypography>
                      <MDTypography variant="caption" fontWeight="regular">
                        {issue.status && formatMessage({ id: issue.status })}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mb={2} mx={2} mt={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDTypography variant="subtitle1" fontWeight="regular">
                        Aberto em:
                      </MDTypography>
                      <MDTypography variant="caption" fontWeight="regular">
                        {issue.createdAt && Formatters.dateTime(parseISO(issue.createdAt))}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDTypography variant="subtitle1" fontWeight="regular">
                        Última atualização:
                      </MDTypography>
                      <MDTypography variant="caption" fontWeight="regular">
                        {issue.updatedAt && Formatters.dateTime(parseISO(issue.updatedAt))}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            )}
            <Grid item xs={12} md={6} xl={12}>
              <MDBox mt={4} mb={1} mr={1} ml={1} display="flex" justifyContent="space-between">
                <MDButton
                  variant="gradient"
                  color="secondary"
                  onClick={() => {
                    navigate(-1);
                  }}
                  startIcon={<ArrowBackRounded />}
                >
                  Voltar
                </MDButton>
                <MDBox display="flex" justifyContent="flex-end">
                  {issue.status && issue.status !== StatusIssueEnum.Closed && (
                    <MDBox>
                      <MDButton
                        variant="gradient"
                        color="primary"
                        onClick={() => close()}
                        sx={{ marginLeft: 10 }}
                      >
                        {closing ? (
                          <MDBox display="flex" justifyContent="space-between" color="text">
                            <CircularProgress color="text" size={15} />
                            &nbsp;Fechando chamado
                          </MDBox>
                        ) : (
                          "Fechar chamado"
                        )}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => setIsOpenNewInteraction(true)}
                        sx={{ marginLeft: 10 }}
                      >
                        Responder
                      </MDButton>
                    </MDBox>
                  )}
                  {isNew && (
                    <MDButton
                      variant="gradient"
                      color="primary"
                      onClick={save}
                      sx={{ marginLeft: 10 }}
                    >
                      {saving ? (
                        <MDBox display="flex" justifyContent="space-between" color="text">
                          <CircularProgress color="text" size={15} />
                          &nbsp;Salvando
                        </MDBox>
                      ) : (
                        "Abrir chamado"
                      )}
                    </MDButton>
                  )}
                </MDBox>
              </MDBox>
            </Grid>
          </Card>

          <MDBox mt={3}>
            <Grid container spacing={3}>
              {issue?.interactions
                ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                .map((item) => (
                  <Grid item xs={12} md={12}>
                    <Card>
                      <MDBox m={3}>
                        <MDBox
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          justifyContent="center"
                          sx={{ gap: 1 }}
                        >
                          <MDTypography variant="button" fontWeight="medium">
                            Mensagem de {item.userName}
                          </MDTypography>
                          <MDTypography variant="caption" fontWeight="regular">
                            em {Formatters.dateTime(parseISO(item.createdAt))}
                          </MDTypography>
                          <MDTypography variant="caption" color="text">
                            {item.text}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </MDBox>
        </MDBox>
      )}

      <NewInteraction
        isOpen={isOpenNewInteraction}
        onClose={() => {
          setIsOpenNewInteraction(false);
        }}
        action={(message) => postInteraction(message)}
        saving={postingInteraction}
      />
    </DashboardLayout>
  );
}

export default Issue;
