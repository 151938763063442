import PropTypes from "prop-types";
import MDBox from "components/CTFramework/MDBox";
import DataTable from "components/DataTable";

function List({ rows }) {
  const columns = [
    {
      Header: "",
      accessor: "select",
      align: "right",
      width: "5px",
    },
    { Header: "Messagem", accessor: "message", width: "600px", align: "left" },
    { Header: "Aberto em", accessor: "createdAt", align: "left" },
  ];

  return (
    <MDBox pt={3} pb={2} px={2}>
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={0}
        m={0}
        sx={{ listStyle: "none" }}
      >
        <DataTable table={{ columns, rows }} noEndBorder entriesPerPage={false} isSorted={false} />
      </MDBox>
    </MDBox>
  );
}

List.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default List;
