import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useAuth } from "context";

import MDBox from "components/CTFramework/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import ProfileInfoCard from "components/Cards/InfoCards/ProfileInfoCard";

import Header from "./components/Header";
import PlatformSettings from "./components/PlatformSettings";

function Profile() {
  const { getUser } = useAuth();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={6} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="Seus dados"
                description=""
                info={{
                  Nome: getUser().name,
                  email: getUser().username,
                }}
                social={[
                  {
                    link: getUser().facebook,
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: getUser().twitter,
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: getUser().instagram,
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <PlatformSettings />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default Profile;
