import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Backdrop,
  Card,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { endOfMonth, format, parseISO, startOfMonth } from "date-fns";

import MDTypography from "components/CTFramework/MDTypography";
import { AxiosContext } from "context";
import Formatters from "helpers/formatters";
import MDButton from "components/CTFramework/MDButton";
import { DatePicker } from "@mui/x-date-pickers";
import DataTable from "components/DataTable";
import MixedChart from "components/Charts/MixedChart";
import MDBox from "components/CTFramework/MDBox";
import NewBill from "components/NewBill";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";

function BillsList({ showChart, type }) {
  const { authAxios } = useContext(AxiosContext);
  const confirm = useConfirm();

  const [bill, setBill] = useState();
  const [rows, setRows] = useState([]);
  const [dateStart, setDateStart] = useState(startOfMonth(new Date()));
  const [dateEnd, setDateEnd] = useState(endOfMonth(new Date()));
  const [chart, setChart] = useState({});
  const [isOpenNewBill, setIsOpenNewBill] = useState(false);
  const [loading, setLoading] = useState(true);

  const columns = [
    { Header: "Programa", accessor: "program", width: "30%", align: "left" },
    { Header: "Descrição", accessor: "description", width: "30%", align: "left" },
    { Header: "Data", accessor: "date", align: "left" },
    { Header: "Parcela", accessor: "entries", align: "center" },
    { Header: "Valor", accessor: "value", align: "right" },
    { Header: "", accessor: "action", align: "right", width: "5px" },
  ];

  async function fetchBills() {
    if (!showChart) {
      setLoading(true);
    }
    const response = await authAxios.get(
      `/financial/v1/bills?${type ? `type=${type}` : ""}&dateStart=${format(
        dateStart,
        "yyyy-MM-dd"
      )}&dateEnd=${format(dateEnd, "yyyy-MM-dd")}`
    );

    parseRows(response.data);

    if (!showChart) {
      setLoading(false);
    }
  }

  const remove = async (id) => {
    try {
      confirm({
        description: "Deseja realmente apagar esse lançamento?",
      }).then(async () => {
        await authAxios.delete(`/financial/v1/bills/${id}`);

        fetchBills();
      });
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const clone = (item) => {
    setBill(item);
    setIsOpenNewBill(true);
  };

  const parseRows = (items) => {
    setRows(
      items.map((item) => ({
        program: (
          <MDTypography variant="button" fontWeight="medium" gutterBottom>
            {item.program ? `${item.program?.config?.name} - ${item.program?.description}` : ""}
          </MDTypography>
        ),
        description: (
          <MDTypography variant="button" fontWeight="medium" gutterBottom>
            {item.description}
          </MDTypography>
        ),
        date: (
          <MDTypography variant="caption" fontWeight="regular">
            {Formatters.dateShort(parseISO(item.date))}
          </MDTypography>
        ),
        entries: (
          <MDBox>
            <MDTypography variant="button" fontWeight="medium">
              {item.entry}/{item.entries}
            </MDTypography>
          </MDBox>
        ),
        value: (
          <MDTypography
            variant="button"
            fontWeight="medium"
            color={item.type === "incoming" ? "success" : "error"}
          >
            {Formatters.currency(item.value)}
          </MDTypography>
        ),
        action: (
          <MDBox>
            {!item.program && (
              <MDBox display="flex" flexDirection="row">
                <Tooltip title="Copiar dados do lançamento">
                  <IconButton onClick={() => clone(item)}>
                    <Icon>content_copy</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Remover lançamento">
                  <IconButton onClick={() => remove(item.id)}>
                    <Icon color="error">delete</Icon>
                  </IconButton>
                </Tooltip>
              </MDBox>
            )}
          </MDBox>
        ),
      }))
    );
  };

  async function fetchChart() {
    setLoading(true);
    const response = await authAxios.get(`/financial/v1/bills/cash/flow`);

    const chartConfig = {
      labels: [],
      datasets: [
        { label: "Receitas", data: [], color: "success" },
        { label: "Despesas", data: [], color: "error" },
        {
          label: "Resultado",
          data: [],
          color: "info",
          chartType: "default-line",
        },
      ],
    };

    response.data.balance.forEach((item) => {
      chartConfig.labels.push(item.month);
      chartConfig.datasets[2].data.push(item.value);
    });

    response.data.incoming.forEach((item) => {
      chartConfig.datasets[0].data.push(item.value);
    });

    response.data.outcoming.forEach((item) => {
      chartConfig.datasets[1].data.push(item.value);
    });

    setChart(chartConfig);

    setLoading(false);
  }

  useEffect(() => {
    fetchBills();
    if (showChart) {
      fetchChart();
    }
  }, []);

  useEffect(() => {
    fetchBills();
  }, [dateEnd, dateStart]);

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && (
        <Grid container>
          {showChart && (
            <Grid item xs={12}>
              <Card>
                <MixedChart chart={chart} title="Receitas x Despesas" />
              </Card>
            </Grid>
          )}
          <Grid item xs={12}>
            <Card sx={{ marginTop: "30px" }}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                py={4}
                px={2}
              >
                <MDBox color="text" mx={1} mt={1.5} lineHeight={0}>
                  <MDTypography variant="button" fontWeight="medium">
                    Detalhamento
                  </MDTypography>
                </MDBox>

                <MDBox display="flex" justifyContent="flex-end">
                  <DatePicker
                    label=""
                    value={dateStart}
                    onChange={setDateStart}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} />}
                    maxDate={dateEnd}
                  />
                  <MDBox color="text" mx={1} mt={1.5} lineHeight={0}>
                    <MDTypography variant="button" fontWeight="medium">
                      até
                    </MDTypography>
                  </MDBox>
                  <DatePicker
                    label=""
                    value={dateEnd}
                    onChange={setDateEnd}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} />}
                    minDate={dateStart}
                  />
                  <MDBox color="text" mx={1.5}>
                    <MDButton
                      color="info"
                      onClick={() => {
                        fetchBills();
                      }}
                    >
                      <Icon>search</Icon>
                    </MDButton>
                  </MDBox>
                  {type && (
                    <MDBox color="text" ml={1}>
                      <MDButton
                        color="info"
                        onClick={() => {
                          setBill(null);
                          setIsOpenNewBill(true);
                        }}
                      >
                        <Icon>add</Icon>
                      </MDButton>
                    </MDBox>
                  )}
                </MDBox>
              </MDBox>

              <MDBox pt={3} pb={2} px={2}>
                <DataTable
                  table={{ columns, rows }}
                  noEndBorder
                  entriesPerPage={false}
                  isSorted={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      )}

      {type && (
        <NewBill
          isOpen={isOpenNewBill}
          onClose={(reload) => {
            if (reload) {
              fetchBills();
            }
            setIsOpenNewBill(false);
          }}
          type={type}
          bill={bill}
        />
      )}
    </>
  );
}

BillsList.defaultProps = {
  showChart: false,
  type: null,
};

BillsList.propTypes = {
  showChart: PropTypes.bool,
  type: PropTypes.string,
};

export default BillsList;
