import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CircularProgress, Modal } from "@mui/material";

import MDBox from "components/CTFramework/MDBox";
import MDInput from "components/CTFramework/MDInput";
import MDButton from "components/CTFramework/MDButton";

function NewInteraction({ isOpen, onClose, action, saving }) {
  const [text, setText] = useState("");

  useEffect(() => {
    if (isOpen) {
      setText("");
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={() => onClose()}>
      <MDBox
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
        }}
      >
        <Card color="primary">
          <MDBox display="flex" flexDirection="row" m={2}>
            <MDInput
              type="text"
              label="Mensagem"
              fullWidth
              value={text}
              onChange={(e) => setText(e.target.value)}
              rows={5}
              multiline
              required
            />
          </MDBox>

          <MDBox mt={4} mb={1} mr={1} ml={1} display="flex" justifyContent="space-between">
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => {
                onClose();
              }}
            >
              Voltar
            </MDButton>
            <MDButton variant="gradient" color="primary" onClick={() => action(text)}>
              {saving ? (
                <MDBox display="flex" justifyContent="space-between" color="text">
                  <CircularProgress color="text" size={15} />
                  &nbsp;Salvando
                </MDBox>
              ) : (
                "Salvar"
              )}
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
  );
}

NewInteraction.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default NewInteraction;
